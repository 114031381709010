<template>
  <q-banner
    rounded
    class="bg-menu text-white"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <div class="text-h6 q-mb-sm">{{ customer.fullname }}</div>

    <p class="q-mb-xs">Email: {{ customer.email || '-' }}</p>
    <p class="q-mb-xs">Telefono: {{ customer.phone || '-' }}</p>
    <p class="q-mb-xs">Codice Fiscale: {{ customer.fiscal_code || '-' }}</p>
    <p class="q-mb-xs">Partita IVA: {{ customer.vat_number || '-' }}</p>

    <div class="text-subtitle2 q-mt-md">Indirizzo</div>
    {{ customer.address.route }}
    <br/>
    {{ customer.address.zipcode }}, {{ customer.address.city }}, {{ customer.address.district }}

  </q-banner>
</template>

<script>
export default{
  name: 'CustomerBanner',

  props: {
    customer: Object,
  },

  setup(){
  },

}
</script>

