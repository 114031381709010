<template>
  <div class="flex justify-center q-pa-none">

    <q-btn-dropdown
      split
      :color="status.color"
      @click="cycle"
    >

      <template #label>
        <q-icon
          :name="status.icon"
          size="lg">
          <q-tooltip>{{ status.label }}</q-tooltip>
        </q-icon>
      </template>

      <q-list>
        <template v-for="sItem in collection">
          <q-item
            clickable
            @click="$emit( 'change', sItem.value )"
            :key="`status${sItem.value}`"
            :active="sItem.value == value"
            active-class="bg-grey-3 text-black"
            v-close-popup>

            <q-item-section side>

              <q-avatar
                :color="sItem.color"
                :icon="sItem.icon"
                text-color="white" />

            </q-item-section>

            <q-item-section>
              <q-item-label>{{ sItem.label }}</q-item-label>
            </q-item-section>

            <q-item-section v-if="sItem.value == value" side>
              <q-item-label camption>
                <q-icon name="mdi-pin" title="stato attuale" />
              </q-item-label>
            </q-item-section>

          </q-item>
        </template>
      </q-list>

    </q-btn-dropdown>

  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useGetters, useState } from '../../hooks/store.js'

export default{
  name: 'TaskStatusButton',

  props: {
    value: {
      type: Number,
    },

  },

  setup(props, {emit}){
    const { getStatus } = useGetters( 'task', [ 'getStatus' ] )
    const { status: collection } = useState( 'task', [ 'status' ] )

    const status = computed( () => getStatus( props.value ).value )

    function cycle(){
      emit( 'change', ( getStatus( props.value + 1 ).value ?? getStatus( 1 ).value  ).value )
    }

    return {
      collection,
      cycle,
      status
    }
  }
}
</script>

