<template>
  <div>
    <template v-for="(sparePart, index) in value">
      <OperationSparePartItem
        class="q-mb-sm q-py-sm bg-grey-3"
        v-if="!inEditing || editing.id != sparePart.id"
        :key="`sp-${sparePart.id}`"
        :readonly="inEditing"
        :spare-part="sparePart"
        @remove="remove(index)"
        @recovery="recovery(index)"
        @edit="edit(index)"
      />

      <q-card
        class="q-mb-sm q-py-sm bg-grey-3"
        :key="`sp-edit-${sparePart.id}`"
        flat
        bordered
        v-else
      >
        <SparePartUseForm
          class="q-px-md q-py-sm"
          @close="editing = {}"
          @submit="save"
          v-model="editing"
          :vehicle="vehicle"
          :operation="operation"
        />
      </q-card>
    </template>

    <q-card
      class="q-mb-sm q-py-sm bg-grey-3"
      flat
      bordered
      v-if="editing.id == 'new'"
    >
      <SparePartUseForm
        class="q-px-md q-py-sm"
        @close="editing = {}"
        @submit="save"
        v-model="editing"
        :vehicle="vehicle"
        :operation="operation"
      />
    </q-card>

    <BaseBtn
      v-if="!inEditing"
      @click="add"
      class="q-mt-sm"
      label="Aggiungi nuovo"
      icon="mdi-plus"
    />
  </div>
</template>

<script>
import OperationSparePartItem from './OperationSparePartItem.vue'
import SparePartUseForm from '../spare-parts/SparePartUseForm.vue'
import { formatMoney } from '../../hooks/helpers.js'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    OperationSparePartItem,
    SparePartUseForm,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    vehicle: {
      type: Number,
    },

    operation: {
      type: Number,
    },
  },

  setup(props, { emit, root }) {
    const editing = ref({})

    const inEditing = computed(() => editing.value.id !== undefined)

    function add() {
      editing.value = { id: 'new' }
    }

    function edit(index) {
      editing.value = Object.assign({}, props.value[index], { index: index })
    }

    function remove(index) {
      const spareParts = props.value

      root.$set(spareParts[index], 'remove', true)

      emit('input', spareParts)
    }

    function recovery(index) {
      const spareParts = props.value

      root.$set(spareParts[index], 'remove', false)

      emit('input', spareParts)
    }

    function save(sparePart) {
      const spareParts = props.value

      if (sparePart.id == 'new' || sparePart.id === 0) {
        spareParts.push({ ...sparePart, id: `new_${new Date().getTime()}` })
      } else {
        const index = spareParts.findIndex((item) => item.id == sparePart.id)

        if (index < 0) {
          spareParts.push({ ...sparePart })
        } else {
          spareParts.splice(index, 1, { ...sparePart, remove: false })
        }
      }

      emit('input', spareParts)

      editing.value = {}
    }

    return {
      add,
      edit,
      editing,
      formatMoney,
      inEditing,
      recovery,
      remove,
      save,
    }
  },
}
</script>
