<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners">

    <q-list v-if="operations.length > 0">
      <template v-for="(operation,index) in operations">

        <q-separator
          :key="`op-sep-${index}`"/>

        <OperationItem
          :key="`op-${index}`"
          :operation="operation"
          @click="id => $emit( 'show', id )"
        />

      </template>
    </q-list>

    <q-card-section class="q-pt-none" v-else>
      <BaseBanner
        icon="mdi-information-outline"
        message="Nessuna operazione eseguita" />
    </q-card-section>

    <template #header-action>
      <BaseBtn
        round
        class="q-ml-auto self-center q-mb-sm"
        size="sm"
        color="pink"
        icon="mdi-plus"
        @click="$emit( 'create' )">
        <q-tooltip>Nuova operazione</q-tooltip>
      </BaseBtn>
    </template>

  </BaseCard>
</template>

<script>
import OperationItem from './OperationItem.vue'

export default{
  name: 'OperationsCard',

  components: {
    OperationItem,
  },

  props: {
    operations: Array,

    subtitle: {
      type: String,
      defaul: null,
    },

    title: {
      type: String,
      default: 'Operazioni eseguite',
    },

  },

}
</script>

