<template>
  <BasePage
    padding
    title="Schede manutenzione"
    subtitle="gestione schede"
    :breadcrumb="$route.matched"
  >

    <template #actions>
      <q-btn
        round
        color="pink"
        icon="mdi-plus"
        @click="show( 'create' )">
        <q-tooltip>Nuova scheda</q-tooltip>
      </q-btn>
    </template>

    <OperationShow
      v-if="detailOperation"
      :operation="$route.params.operation"
      :action="$route.params.operation_action"
      :maintenance="detail"
      @destroy="operationDestroy"
      @store="operationStore"
      @update="operationUpdate"
    />

    <template v-else>
      <!-- detail -->
      <template v-if="detailShow">

        <MaintenanceCreateStepper
          v-if="detailCreate"
          v-model="editable"
          :errors="errors"
          @submit="save"
        />

        <template v-else>

          <MaintenanceEdit
            v-model="editable"
            :errors="errors"
            :status-changed="editable.status.id!=detail.status.id"
            @update-status="status => setStatus( status.id )"
            @undo-status="editable.status = { ...detail.status }"
            v-if="detailEdit">

            <BaseBtn
              color="pink"
              label="Salva le modifiche"
              icon="mdi-content-save-outline"
              @click="update" />

            <BaseBtn
              color="black"
              label="Torna alla scheda"
              class="q-ml-xl"
              @click="show()" />

          </MaintenanceEdit>

          <MaintenanceShow
            v-model="detail"
            @edit="edit"
            @destroy="destroy"
            @update-status="status => setStatus( status.id )"
            v-else />

          <BaseModal
            confirm
            v-model="detailDestroy"
            @close="show()"
            @confirm="apiDestroy( detail.id )"
            title="Conferma richiesta"
            label="Elimina"
            ko-label="Annulla">
            Rimuovere la manutenzione selezionata?
          </BaseModal>

        </template>

      </template>

      <MaintenanceTableList v-else
        :data="data"
        :loading="loading"
        :pagination.sync="pagination"
        :filters="filters"
        @filter="newFilters => filters = newFilters"
        @row-click="(e,row) => show( row.id )"
        @request="onRequest" />

    </template>

  </BasePage>
</template>

<script>
import Attachs from '../apis/attachs.js'
import Maintenances from '../apis/maintenances.js'
import MaintenanceCreateStepper from '../components/maintenances/MaintenanceCreateStepper.vue'
import MaintenanceEdit from '../components/maintenances/MaintenanceEdit.vue'
import MaintenanceShow from '../components/maintenances/MaintenanceShow.vue'
import MaintenanceTableList from '../components/maintenances/MaintenanceTableList.vue'
import OperationShow from '../components/operations/OperationShow.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import useNotify from '../hooks/notify.js'
import { useActions } from '../hooks/store.js'
import { computed, toRefs } from '@vue/composition-api'

export default{
  name : 'Maintenances',

  components: {
    MaintenanceCreateStepper,
    MaintenanceEdit,
    MaintenanceShow,
    MaintenanceTableList,
    OperationShow,
  },

  setup(_,ctx){

    const { setDirty } = useActions([ 'setDirty' ])

    const {
      apiDestroy,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      pagination,
      saving,
      show,
      state,
      save : apiSave,
    } = useApiCrudTable({
      api: Maintenances,
      ctx,
      events : {
        destroy( response ){

          const index = state.data.findIndex( item => item.id == state.detail.id )
          state.data.splice( index, 1 )

          success( 'Eliminazione completata' )
          return response
        },
        store( response ){
          //skip dirty alert after store new item
          setDirty( false )
          show( response.data.id )
          return response
        },
        update( response ){
          success( 'Modifiche registrate' )
          return response
        },
      },
      routes: {
        create: 'maintenanceCreate',
        destroy: 'maintenanceDestroy',
        edit: 'maintenanceEdit',
        index: 'maintenances',
        show: 'maintenanceShow',
        operations: 'maintenanceOperation',
      },
      filters: {
        sort: 'registration_date',
        dir: 'desc',
        kind: '',
        status: '',
        query: '',
        from: '',
        to: '',
        user: '',
        customer: '',
      },
    })

    const { success, ongoing, update: notifyUpdate } = useNotify()

    //load status
    const { loadStatus } = useActions( 'maintenance', [ 'loadStatus' ] )
    loadStatus()

    function update()
    {
      //prepare tasks to submit
      state.editable.tasks.map( task => {
        //id new stripped
        if( /^new_/.test(task.id ) ){
          delete task.id
        }
        //remove not true
        if( task.remove !== undefined && !task.remove ){
          delete task.remove
        }
      })

      save()
    }

    async function save()
    {
      let notifyLoading = ''

      for( let taskIndex in state.editable.tasks ){
        let task = state.editable.tasks[ taskIndex ]

        if( !task.remove ){
          for( let attachIndex in task.attachs ) {

            let attach = task.attachs[ attachIndex ]

            //to upload need
            if( !attach.id && !attach.remove ){

              //start upload
              notifyLoading = ongoing( `Caricamento del file "${attach.name}"` )

              await Attachs.upload( attach )
                .then( response => {
                  notifyUpdate( notifyLoading, `Caricamento completato` )

                  //set attach
                  state.editable.tasks[ taskIndex ].attachs[ attachIndex ] = response.data

                }).catch( () => {
                  notifyUpdate( notifyLoading, `Caricamento del file "${attach.name} non riuscito"`, { type: 'negative', icon : 'mdi-alert-outline' } )
                })
            }

          }
        }
      }

      apiSave()
    }

    function setStatus( status )
    {
      Maintenances
        .setStatus( state.editable.id, status )
        .then( response => {
          state.detail = Object.assign(state.detail, { ...response.data })
          state.editable = Object.assign(state.editable, { ...response.data })

          if( state.detailIndex !== undefined ){
            state.data[ state.detailIndex ].status = response.data.status
          }
        })
    }

    const detailOperation = computed( () => state.action == 'operations' )

    function operationDestroy( id )
    {
      const index = state.detail.operations.findIndex( item => id == item.id )
      state.detail.operations.splice( index, 1 )
    }

    function operationStore( operation )
    {
      state.detail.operations.push( operation )
    }

    function operationUpdate( operation )
    {
      const index = state.detail.operations.findIndex( item => operation.id == item.id )
      if( index < 0 ) {
        operationStore( operation )
      }
      else {
        state.detail.operations.splice( index, 1, operation )
      }
    }

    return {
      apiDestroy,
      destroy,
      detailOperation,
      edit,
      errors,
      loading,
      onRequest,
      operationDestroy,
      operationStore,
      operationUpdate,
      pagination,
      saving,
      show,
      save,
      setStatus,
      update,
      ...toRefs(state),
    }
  }
}
</script>
