<template>
  <q-markup-table
    separator="cell"
    flat
    bordered>
    <thead>
      <tr>
        <th class="text-left">Codice</th>
        <th class="text-left">Informazioni</th>
        <th class="text-right">Quantità</th>
        <th class="text-right">Costo Uni.</th>
        <th class="text-right">Costo Totale</th>
        <th class="text-right">Prezzo Uni.</th>
        <th class="text-right">Prezzo Totale</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="item in data">
        <SparePartsTableRow :data="item" :key="`sp-${item.id}`" />
      </template>
      <tr class="bg-grey-2">
        <th colspan="3">&nbsp;</th>
        <th class="text-right" colspan="2">{{ totalCost }}</th>
        <th class="text-right" colspan="2">{{ totalPrice }}</th>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script>
import SparePartsTableRow from './SparePartsTableRow.vue'
import { formatMoney } from '../../hooks/helpers.js'
import { computed } from '@vue/composition-api'

export default {
  name: 'SparePartsTable',

  components: {
    SparePartsTableRow,
  },

  props: {
    data: {
      type: Array,
    },
  },

  setup({data}){

    const totalCost = computed(() => {
      let total = 0
      data.forEach( item => total+= item.quantity * item.cost )
      return formatMoney( total )
    })

    const totalPrice = computed(() => {
      let total = 0
      data.forEach( item => total+= item.quantity * item.price )
      return formatMoney( total )
    })

    return {
      totalCost,
      totalPrice,
    }
  },
}
</script>

