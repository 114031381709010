import Api from './api.js'

const END_POINT = 'tasks'

export default {
  setStatus(id, status) {
    return Api.patch(`${END_POINT}/${id}/status`, { status })
  },

  dailyGrind(completed = false) {
    return Api.get(`${END_POINT}/daily-grind`, { params: { completed } })
  },
}
