<template>
  <form @submit.prevent="submit">
    <div class="flex">
      <BaseInput
        ref="title"
        class="q-mr-lg col-grow"
        label="Titolo"
        v-model="task.title"
      />

      <BaseSelect
        label="Priorità"
        style="width: 120px; max-width: 120px"
        native
        v-model="task.priority"
        :display-value="task.priority ? task.priority.label : ''"
        :options="priorities"
      />
    </div>

    <BaseInput
      label="Descrizione"
      type="textarea"
      autogrow
      v-model="task.description"
    />

    <AttachsForm
      v-model="task.attachs"
      camera
      counter
      multiple
      color="secondary"
    />

    <TaskPlanningForm v-if="canManage" v-model="task" />

    <div class="flex items-center q-mt-lg">
      <BaseBtn
        :label="updating ? 'Salva' : 'Aggiungi'"
        type="submit"
        :disable="!taskHaveTitle"
      />

      <BaseBtn
        v-if="updating"
        class="q-ml-md"
        label="Annulla"
        type="button"
        color="black"
        @click="$emit('close')"
      />

      <p
        v-if="!taskHaveTitle"
        class="
          text-grey text-caption
          q-pb-none q-mb-none q-ml-md
          flex
          items-center
        "
      >
        <q-icon name="mdi-help-circle" class="q-mr-sm text-pink" /> Inserisci
        almeno il titolo per aggiungere
      </p>
    </div>
  </form>
</template>

<script>
import useNotify from '../../hooks/notify.js'
import AttachsForm from '../attachs/AttachsForm.vue'
import TaskPlanningForm from './TaskPlanningForm.vue'
import { reactive, computed } from '@vue/composition-api'
import { useState, useGetters } from '../../hooks/store.js'

export default {
  components: {
    AttachsForm,
    TaskPlanningForm,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    updating: Boolean,
  },

  setup(props, ctx) {
    const { priorities } = useState('task', ['priorities'])
    const { getPriority } = useGetters('task', ['getPriority'])
    const { userCan } = useGetters('auth', ['userCan'])
    const { success } = useNotify()

    const task = reactive({
      title: '',
      description: '',
      priority: 1,
      attachs: [],
      user: { id: null },
      ...props.value,
    })

    const defaultPriority = Object.assign({}, getPriority(task.priority).value)
    task.priority = defaultPriority

    const taskHaveTitle = computed(() => task.title != '')

    function submit() {
      if (taskHaveTitle.value) {
        //emit, mutate priority in linear value
        ctx.emit(
          'submit',
          Object.assign({}, task, { priority: task.priority.value })
        )

        if (!props.updating) {
          //reset
          task.title = ''
          task.description = ''
          task.priority = defaultPriority
          task.attachs = []
          task.user = { id: null }

          //refocus on input
          ctx.refs.title.$children[0].focus()

          success('Anomalia registrata', { group: true })
        }
      }
    }

    const canManage = computed(() => userCan('tasks_manage').value)

    return {
      canManage,
      priorities,
      task,
      taskHaveTitle,
      submit,
    }
  },
}
</script>
