<template>
  <form @submit.prevent="submit">
    <div class="flex column row-md q-mb-sm q-col-gutter-md">
      <SparePartAutocomplete
        label="Ricambio"
        class="col-grow"
        error-name="product"
        free-brand
        :errors="errors"
        :value="sparePart"
        @input="change"
      />

      <BaseInput
        v-model.number="sparePart.quantity"
        error-name="quantity"
        :errors="errors"
        type="number"
        min="1"
        label="Quantità utilizzata"
      />
    </div>

    <BaseBanner
      v-if="!sparePart.id || sparePart.id == 'new'"
      class="q-mb-lg"
      message="Non trovi il ricambio? Puoi inviare una richiesta d'ordine."
    >
      <template #action>
        <BaseBtn @click="showModal = true">
          <q-tooltip>
            Effettua una richiesta d'ordine per un ricambio
          </q-tooltip>
          Richedi ora
        </BaseBtn>
      </template>

      <SparePartRequestModal
        v-model="showModal"
        :vehicle="vehicle"
        :operation="operation"
        @hide="showModal = false"
      />
    </BaseBanner>

    <BaseBanner v-else class="q-mb-lg">
      <p class="q-my-sm" v-if="!!sparePart.description">
        <q-icon name="mdi-text" />
        {{ sparePart.description }}
      </p>

      <p class="q-my-sm">
        Quantità disponibile a magazzino: <b>{{ sparePart.buffer }} pz</b>
        <template v-if="sparePart.reserved > 0">
          di cui <b>{{ sparePart.reserved }} pz</b> riservati da un altro
          operatore.
        </template>
      </p>
    </BaseBanner>

    <BaseBanner
      color="negative"
      icon="mdi-help-circle-outline"
      v-if="sparePart.quantity + sparePart.reserved > sparePart.buffer"
      class="q-mb-lg"
    >
      Il ricambio selezionato non è disponibile a magazzino nella quantità
      necessaria.
      <br />
      Al momento del salvataggio della scheda verrà inviata una richiesta
      d'ordine per
      <b>{{ sparePart.quantity - sparePart.buffer }}</b>
      pezzi.
    </BaseBanner>

    <div class="flex items-center">
      <BaseBtn label="Salva" type="submit" />

      <BaseBtn
        class="q-ml-md"
        label="Annulla"
        type="button"
        color="black"
        @click="$emit('close')"
      />
    </div>
  </form>
</template>

<script>
import SparePartAutocomplete from './SparePartAutocomplete.vue'
import SparePartRequestModal from './SparePartRequestModal.vue'
import useApiErrors from '../../hooks/apiErrors.js'
import { reactive, ref, watch } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'SparePartUseForm',

  components: {
    SparePartAutocomplete,
    SparePartRequestModal,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    vehicle: {
      type: Number,
    },

    operation: {
      type: Number,
    },
  },

  setup(props, ctx) {
    const showModal = ref(false)

    const sparePart = reactive({
      code: '',
      name: '',
      description: '',
      quantity: 1,
      reserved: 0,
      ...props.value,
    })

    const errors = useApiErrors()

    function validate() {
      errors.clearError()

      const occurence = {}

      if (sparePart.id == null) occurence.product = ['Seleziona un prodotto']
      if (!sparePart.code) occurence.code = ['Inserisci un codice']
      if (!sparePart.name) occurence.name = ['Inserisci un nome']
      if (!sparePart.quantity) occurence.quantity = ['Inserisci una quantità']

      if (!isEmpty(occurence)) {
        errors.setError({
          message: 'Ricontrolla i dati',
          errors: occurence,
        })
      }
    }

    //errors
    watch(
      () => sparePart,
      () => {
        if (errors.hasError()) validate()
      },
      { deep: true }
    )

    function change(value) {
      if (!value) return

      const fields = [
        'id',
        'code',
        'name',
        'description',
        'buffer',
        'reserved',
        'brand',
      ]
      fields.forEach((field) => (sparePart[field] = value[field]))
    }

    function clear() {
      change({
        id: null,
        code: '',
        name: '',
        description: '',
        brand: { id: null, name: '' },
      })
    }

    function submit() {
      validate()

      if (!errors.hasError()) {
        ctx.emit('submit', sparePart)
      }
    }

    return {
      change,
      clear,
      errors,
      sparePart,
      submit,
      showModal,
    }
  },
}
</script>
