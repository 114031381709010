<template>

  <BaseSelect
    use-input
    input-debounce="0"
    ref="select"
    v-bind="{ label:'Cerca tra le tipologie', ...$attrs}"
    v-on="{ filter, ...$listeners }"
    :value="value"
    :options="options"
    :display-value="value.name ? value.name : ''"
    @input-value="inputValue">

    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction:row!important">
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong>"
          </span>
          <BaseBtn
            v-if="creable"
            @click="create"
            label="Crea"
            icon="mdi-plus"
          />
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item
        v-bind="scope.item.itemProps"
        v-on="scope.item.itemEvents"
      >
        <q-item-section>
          <q-item-label>{{ scope.item.opt.name }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>

  </BaseSelect>

</template>

<script>
import { useActions, useGetters } from '../../hooks/store.js'
import { computed, ref } from '@vue/composition-api'

export default{
  name: 'OperationTypeAutocomplete',
  inheritAttrs: false,

  props: {
    creable: Boolean,
    inputPreserveValue: Boolean,
    value: {
      type: Object,
    },
  },

  setup({inputPreserveValue}, {emit, refs}){
    //operation types
    const { loadTypes, storeType } = useActions( 'operation', [ 'loadTypes', 'storeType' ] )
    const { filterTypes } = useGetters( 'operation', [ 'filterTypes' ] )
    loadTypes()

    const query = ref('')
    const options = computed( () => {
      return filterTypes( query.value ).value
    })

    function filter( query, update )
    {
      update( filterTypes( query ) )
    }

    function inputValue( value )
    {
      query.value = value

      if( !inputPreserveValue && value.length > 0 ) emit( 'input', {} )
    }

    function create(){
      storeType( { name: query.value } ).then( value => {
        //clear query
        refs.select.$refs.select.updateInputValue( '' )
        emit( 'input', value )
      })
    }

    return {
      filter,
      inputValue,
      query,
      create,
      options,
    }
  }

}
</script>

