<template>
  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns"
  >
    <template #top>
      <MaintenanceListFilters
        :value="filters"
        @input="(filters) => $emit('filter', filters)"
      />
    </template>

    <template v-slot:body="props">
      <q-tr
        @click="$emit('row-click', $event, props.row)"
        :class="
          'cursor-pointer' + (props.row.do_immediatly ? ' bg-lime-2' : '')
        "
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          <StatusBadge v-if="col.name == 'status'" :value="col.value.id" />

          <q-badge
            v-else-if="col.name == 'cup_number'"
            :color="col.value ? 'secondary' : 'grey-1'"
          >
            {{ col.value || 'NA' }}
          </q-badge>

          <q-icon
            v-else-if="col.name == 'do_immediatly'"
            size="sm"
            :name="`mdi-${
              col.value ? 'checkbox-marked-circle-outline' : 'radiobox-blank'
            }`"
            color="grey-1"
          >
            <q-tooltip v-if="col.value">Da fare subito</q-tooltip>
          </q-icon>

          <q-icon
            v-else-if="col.name == 'mandatory'"
            size="sm"
            :name="`mdi-${
              col.value ? 'checkbox-marked-circle-outline' : 'radiobox-blank'
            }`"
            color="grey-1"
          >
            <q-tooltip v-if="col.value">Tassativo</q-tooltip>
          </q-icon>

          <template v-else-if="col.name == 'kind'">
            {{ getKind(col.value).value.label }}
          </template>

          <template v-else-if="col.name == 'creator' || col.name == 'user'">
            {{
              col.value != null
                ? col.value.name !== undefined
                  ? col.value.name || ''
                  : col.value
                : ''
            }}
          </template>

          <template v-else>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import MaintenanceListFilters from './MaintenanceListFilters.vue'
import StatusBadge from './StatusBadge.vue'
import { date } from 'quasar'
import { useGetters } from '../../hooks/store.js'

export default {
  name: 'MaintenanceTableList',
  inheritAttrs: false,

  components: {
    MaintenanceListFilters,
    StatusBadge,
  },

  props: {
    filters: {
      type: Object,
    },
  },

  setup() {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px',
      },
      {
        name: 'status',
        label: 'Stato',
        field: 'status',
        align: 'left',
        style: 'width:180px',
      },
      {
        name: 'customer',
        label: 'Cliente',
        align: 'left',
        field: (row) => (row.customer ? row.customer.fullname : '-'),
      },
      {
        name: 'vehicle',
        label: 'Veicolo',
        align: 'left',
        field: (row) =>
          row.vehicle
            ? `${row.vehicle.model.brand.name} - ${row.vehicle.model.name} ${row.vehicle.model.year}`
            : '-',
        style: 'width:80px',
      },
      {
        name: 'plate_number',
        label: 'Targa veicolo',
        align: 'left',
        field: (row) => (row.vehicle ? row.vehicle.plate_number : '-'),
        style: 'width:80px',
      },
      {
        name: 'do_immediatly',
        label: 'DFS',
        align: 'center',
        field: 'do_immediatly',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'mandatory',
        label: 'Tassativo',
        align: 'center',
        field: 'mandatory',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'kind',
        label: 'Genere',
        align: 'center',
        field: 'kind',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'cup_number',
        label: 'N° mezzo',
        align: 'center',
        field: 'cup_number',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'year',
        label: 'Anno',
        align: 'center',
        field: 'year',
        style: 'width:38px',
      },
      {
        name: 'month',
        label: 'Mese',
        align: 'center',
        field: 'month',
        style: 'width:38px',
      },
      {
        name: 'week',
        label: 'Settimana',
        align: 'center',
        field: 'week',
        style: 'width:38px',
      },
      {
        name: 'registration_date',
        label: 'Data arrivo',
        align: 'left',
        field: 'registration_date',
        sortable: true,
        format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
        style: 'width:80px',
      },
      {
        name: 'delivery_date',
        label: 'Data consegna',
        align: 'left',
        field: 'delivery_date',
        sortable: true,
        format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
        style: 'width:80px',
      },
      {
        name: 'user',
        label: 'Consulente',
        align: 'left',
        field: 'user',
      },
      {
        name: 'creator',
        label: 'Operatore',
        align: 'left',
        field: 'creator',
      },
      {
        name: 'created_at',
        label: 'Creata il',
        align: 'left',
        field: 'created_at',
        sortable: true,
        format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
        style: 'width:80px',
      },
    ]

    const { getKind } = useGetters('maintenance', ['getKind'])

    return {
      columns,
      getKind,
    }
  },
}
</script>
