<template>
  <BaseModal
    v-bind="{ ...$attrs }"
    @hide="$emit('hide')"
    @close="$emit('hide')"
    @confirm="send"
    size="lg"
    confirm
    label="Invia la richiesta"
    title="Richiesta per un nuovo ricambio"
  >
    <BaseInnerLoading :showing="uploading" />

    <BaseInput
      label="Messaggio"
      type="textarea"
      v-model="data.message"
      error-name="message"
      :errors="errors"
    />

    <AttachsForm
      v-model="data.attachs"
      camera
      multiple
      counter
      class="q-mt-md"
      color="secondary"
    />
  </BaseModal>
</template>

<script>
import { reactive } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Attachs from '../../apis/attachs'
import SpareParts from '../../apis/spareParts'
import useApiErrors from '../../hooks/apiErrors'
import useNotify from '../../hooks/notify.js'
import useToggler from '../../hooks/toggler'
import AttachsForm from '../attachs/AttachsForm.vue'

export default {
  name: 'SparePartRequestModal',

  components: {
    AttachsForm,
  },

  props: {
    vehicle: {
      type: Number,
    },
    operation: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const errors = useApiErrors()
    const { success, ongoing, update: notifyUpdate } = useNotify()

    const {
      toggler: uploading,
      on: startUpload,
      off: stopUpload,
    } = useToggler()

    const data = reactive({
      message: '',
      attachs: [],
    })

    function validate() {
      errors.clearError()

      const occurence = {}

      if (!data.message) occurence.message = ['Inserisci un testo']

      if (!isEmpty(occurence)) {
        errors.setError({
          message: 'Ricontrolla i dati',
          errors: occurence,
        })
      }
    }

    async function send() {
      validate()

      if (errors.hasError()) return

      startUpload()

      let notifyLoading = ''
      for (let attachIndex in data.attachs) {
        let attach = data.attachs[attachIndex]

        //to upload need
        if (!attach.id && !attach.remove) {
          //start upload
          notifyLoading = ongoing(`Caricamento del file "${attach.name}"`)

          await Attachs.upload(attach)
            .then((response) => {
              notifyUpdate(notifyLoading, `Caricamento completato`)

              //set attach
              data.attachs[attachIndex] = response.data
            })
            .catch(() => {
              notifyUpdate(
                notifyLoading,
                `Caricamento del file "${attach.name} non riuscito"`,
                { type: 'negative', icon: 'mdi-alert-outline' }
              )
            })
        }
      }

      try {
        const { vehicle, operation } = props
        const params = { ...data, vehicle }
        if (operation > 0) params.operation = operation

        await SpareParts.sendRequest(params)

        //reset form
        data.message = ''
        data.attachs = []

        success('Notifica inviata correttamente')

        emit('hide')
      } catch (e) {
        errors.setError(e)
      } finally {
        stopUpload()
      }
    }

    return {
      errors,
      data,
      send,
      uploading,
    }
  },
}
</script>
