<template>

  <div>

    <q-item class="q-py-none">
      <BaseBanner
        class="flex justify-center text-subtitle1"
        style="width:100%;"
        :color="statusColor">

        <template #avatar v-if="!readonly">
          <BaseBtn
            unelevated
            round
            title="Mostra lo storico"
            icon="mdi-history"
            :color="statusColor"
            :disabled="history.length<2"
            @click="showHistory"
          />
        </template>

        {{ status.name }}

        <template #action>

          <q-btn-dropdown
            v-if="!readonly"
            unelevated
            rounded
            :color="statusColor">

            <template #label>
              <q-icon name="mdi-pencil-outline" />
            </template>

            <q-list>
              <template v-for="sItem in statusList">

                <q-item
                  clickable
                  @click="change(sItem)"
                  :key="`status${sItem.id}`"
                  :active="sItem.id == status.id"
                  active-class="bg-grey-3 text-black"
                  v-close-popup>
                  <q-item-section side>
                    <q-avatar
                      size="sm"
                      :color="sItem.color"
                      text-color="white"
                    >{{ sItem.order }}</q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ sItem.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section v-if="sItem.id == status.id" side>
                    <q-item-label camption>
                      <q-icon name="mdi-pin" title="stato attuale" />
                    </q-item-label>
                  </q-item-section>
                </q-item>

              </template>
            </q-list>

          </q-btn-dropdown>

          <BaseBtn
            v-else
            unelevated
            round
            title="Mostra lo storico"
            icon="mdi-history"
            :color="statusColor"
            :disabled="history.length<2"
            @click="showHistory"
          />

        </template>
      </BaseBanner>
    </q-item>

    <q-item class="q-pt-none" v-if="!hideInfo">
      <q-item-section side>
        <q-item-label caption class="flex items-center">
          <q-icon name="mdi-timer-outline" class="q-mr-xs" />
          {{ formatDate( lastStatus.created_at, 'DD/MM/YYYY, HH:mm' ) }}
        </q-item-label>
      </q-item-section>

      <q-item-section side class="q-ml-auto q-pa-none">
        <q-item-label caption class="flex items-center">
          <q-icon name="mdi-account" class="q-mr-xs" />
          {{ lastStatus.created_from }}
        </q-item-label>
      </q-item-section>
    </q-item>

    <StatusHistoryModal
      v-model="showingHistory"
      :history="history"
      @confirm="hideHistory"
    />

  </div>

</template>

<script>
import StatusHistoryModal from './StatusHistoryModal.vue'
import useToggler from '../../hooks/toggler.js'
import { computed } from '@vue/composition-api'
import { date } from 'quasar'
import { useGetters, useState } from '../../hooks/store.js'

export default{

  props: {
    value: {
      type: Object,
    },
    history : {
      type: Array,
    },
    hideInfo : Boolean,
    readonly: Boolean,
  },

  components: {
    StatusHistoryModal,
  },

  setup(props, {emit}){
    const { status : statusList } = useState( 'maintenance', [ 'status' ] )
    const { getStatusById } = useGetters( 'maintenance', [ 'getStatusById' ] )

    const { toggler: showingHistory, on: showHistory, off: hideHistory } = useToggler()

    const { formatDate } = date

    const status = computed( () => props.value )

    const lastStatus = computed( () => {
      if( !props.history.length ) return ''
      return props.history[ props.history.length - 1 ]
    });

    const statusColor = computed(() => getStatusById( props.value.id ).value.color )

    function change( value )
    {
      const { id, name, order } = value

      emit( 'input', { id, name, order } )
    }

    return {
      change,
      formatDate,
      hideHistory,
      getStatusById,
      lastStatus,
      showHistory,
      showingHistory,
      status,
      statusColor,
      statusList,
    }
  },

}
</script>

