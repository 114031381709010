<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list v-if="tasks.length > 0" class="q-pb-md">
      <template v-for="(item, index) in tasks">
        <q-item :key="`task_${index}`">
          <q-item-section avatar side top>
            <q-icon
              name="mdi-information-outline"
              size="lg"
              :color="getPriorityColor(item.priority).value"
            >
              <q-tooltip
                >Priorità {{ getPriorityLabel(item.priority).value }}</q-tooltip
              >
            </q-icon>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>

            <q-item-label caption v-if="!!item.description" class="q-mb-sm">
              {{ item.description }}
            </q-item-label>

            <TaskPlanning :task="item" inline />

            <q-item-label v-if="item.attachs.length">
              <AttachsList class="q-mt-sm" :attachs="item.attachs" />
            </q-item-label>
          </q-item-section>

          <q-item-section side top>
            <TaskStatusButton
              :value="item.status"
              @change="(status) => setStatus(index, item, status)"
            />
          </q-item-section>
        </q-item>
      </template>
    </q-list>

    <q-card-section class="q-pt-none" v-else>
      <BaseBanner
        icon="mdi-information-outline"
        message="Nessuna anomalia segnalata dal cliente"
      />
    </q-card-section>
  </BaseCard>
</template>

<script>
import AttachsList from '../attachs/AttachsList.vue'
import Tasks from '../../apis/tasks.js'
import TaskStatusButton from './TaskStatusButton.vue'
import { useGetters } from '../../hooks/store.js'
import TaskPlanning from './TaskPlanning.vue'

export default {
  name: 'TasksCard',

  components: {
    AttachsList,
    TaskStatusButton,
    TaskPlanning,
  },

  props: {
    tasks: Array,

    subtitle: {
      type: String,
      defaul: null,
    },

    title: {
      type: String,
      default: 'Anomalie segnalate',
    },
  },

  setup(props, { emit }) {
    const { getPriorityLabel, getPriorityColor } = useGetters('task', [
      'getPriorityLabel',
      'getPriorityColor',
    ])

    function setStatus(index, item, status) {
      Tasks.setStatus(item.id, status).then(() => {
        const tasks = [...props.tasks]
        tasks[index].status = status
        emit('change', tasks)
      })
    }

    return {
      getPriorityLabel,
      getPriorityColor,
      setStatus,
    }
  },
}
</script>
