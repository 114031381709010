<template>
  <div class="row q-mt-lg">
    <div class="col-12 col-md-4">
      <BaseCard multi-section title="Informazioni">
        <q-card-section class="q-pt-none" v-if="maintenance.do_immediatly">
          <BaseBanner
            dense
            message="Da fare subito"
            icon="mdi-alert"
            color="pink"
          />
        </q-card-section>

        <MaintenanceStatusBanner
          v-model="maintenance.status"
          :history="maintenance.statusHistory"
          @input="$emit('update-status', maintenance.status)"
        />

        <q-card-section class="q-pt-none">
          <div class="text-subtitle2">
            <q-icon name="mdi-timer-outline" color="pink" />
            Data e ora arrivo
          </div>
          {{ formatDate(maintenance.registration_date, 'DD/MM/YYYY, HH:mm') }}
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-subtitle2">
            <q-icon name="mdi-calendar-outline" color="pink" />
            Data di consegna
          </div>
          {{
            maintenance.delivery_date
              ? formatDate(maintenance.delivery_date, 'DD/MM/YYYY')
              : '-'
          }}
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-subtitle2">Genere</div>
          {{ getKind(maintenance.kind).value.label }}
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-subtitle2">Aperta da</div>
          {{ maintenance.creator.name }}
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="text-subtitle2">Consulente</div>
          {{ maintenance.user.name || '-' }}
        </q-card-section>

        <template #footer>
          <div class="flex full-width justify-between">
            <div>
              <BaseBtn
                label="Modifica"
                class="q-pr-sm q-mr-sm"
                icon="mdi-pencil"
                @click="$emit('edit')"
              />

              <BaseBtn
                label="Elimina"
                class="q-pr-sm"
                color="negative"
                icon="mdi-delete"
                @click="$emit('destroy')"
              />
            </div>

            <q-btn-dropdown
              color="black"
              label="Scarica PDF"
              icon="mdi-file-pdf"
              :disabled="downloading"
              :loading="downloading"
            >
              <q-tooltip>Scarica la scheda PDF</q-tooltip>

              <q-list>
                <q-item clickable v-close-popup @click="download()">
                  <q-item-section>
                    <q-item-label> Scheda Lavoro </q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="download(true)">
                  <q-item-section>
                    <q-item-label> Scheda Ricambi </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </template>
      </BaseCard>

      <CustomerCard :customer="maintenance.customer">
        <template #footer>
          <BaseBtn
            label="Modifica"
            class="q-pr-sm"
            icon="mdi-pencil"
            @click="customerEdit"
          />

          <CustomerContact class="q-ml-auto" :customer="maintenance.customer" />
        </template>
      </CustomerCard>

      <VehicleCard
        :customer="maintenance.customer"
        :vehicle="maintenance.vehicle"
        :history-mileage="maintenance.mileage"
        @update-mileage="$emit('edit')"
      >
        <template #footer>
          <BaseBtn
            label="Modifica"
            class="q-pr-sm"
            icon="mdi-pencil"
            @click="vehicleEdit"
          />
        </template>
      </VehicleCard>
    </div>

    <div class="col-12 col-md-8 q-pr-md-lg order-md-first">
      <BaseCard multi-section title="Dati di registrazione">
        <div class="row">
          <div class="col-12 col-sm-6">
            <q-card-section class="q-py-none">
              <div class="text-subtitle2 q-pb-xs">
                <q-icon name="mdi-gauge" color="pink" />
                Chilometraggio al momento delle operazioni
              </div>

              {{ maintenance.mileage || '-' }} Km

              <BaseBtn
                @click="$emit('edit')"
                class="q-ml-sm"
                label="Modifica"
                icon="edit"
                size="xs"
                color="pink"
              >
                <q-tooltip>
                  Modifica il chilometraggio al momento delle operazioni
                </q-tooltip>
              </BaseBtn>
            </q-card-section>

            <q-card-section>
              <div class="text-subtitle2 q-mb-xs">
                <q-icon name="mdi-format-list-bulleted" color="pink" />
                Dettagli
              </div>

              <q-list dense>
                <template v-for="(checkboxLabel, checkboxKey) in checkbox">
                  <q-item class="q-px-none" :key="`cb-${checkboxKey}`">
                    <q-item-section side>
                      <q-icon
                        :color="maintenance[checkboxKey] ? 'positive' : 'red-4'"
                        :name="
                          maintenance[checkboxKey]
                            ? 'mdi-check-box-outline'
                            : 'mdi-checkbox-blank-outline'
                        "
                      />
                    </q-item-section>
                    <q-item-section>{{ checkboxLabel }}</q-item-section>
                  </q-item>
                </template>
              </q-list>
            </q-card-section>
          </div>
          <div class="col-12 col-sm-6">
            <q-card-section class="q-pt-none">
              <div class="flex">
                <div class="text-subtitle2 q-pr-md">
                  <q-icon name="mdi-gas-station" color="pink" />
                  Livello carburante
                </div>
                {{ maintenance.fuel_level }}%
              </div>

              <FuelLevel
                readonly
                hide-label
                small
                style="margin-top: -35px"
                v-model="maintenance.fuel_level"
              />
            </q-card-section>
          </div>
        </div>

        <q-card-section class="q-pt-none q-pb-lg" v-if="!!maintenance.info">
          <div class="text-subtitle2">
            <q-icon name="mdi-paperclip" color="pink" />
            Note
          </div>
          <div class="text-italic">
            {{ maintenance.info }}
          </div>
        </q-card-section>
      </BaseCard>

      <TasksCard
        :tasks="maintenance.tasks"
        @change="(tasks) => (maintenance.tasks = tasks)"
      >
        <template #footer>
          <BaseBtn
            label="Modifica"
            class="q-pr-sm"
            icon="mdi-pencil"
            @click="$emit('edit')"
          />
        </template>
      </TasksCard>

      <OperationsCard
        :operations="maintenance.operations"
        @show="operationShow"
        @create="operationCreate"
      />
    </div>
  </div>
</template>

<script>
import CustomerCard from '../customers/CustomerCard.vue'
import FuelLevel from './FuelLevel.vue'
import Maintenances from '../../apis/maintenances.js'
import MaintenanceStatusBanner from './MaintenanceStatusBanner.vue'
import OperationsCard from '../operations/OperationsCard.vue'
import TasksCard from '../tasks/TasksCard.vue'
import useNotify from '../../hooks/notify.js'
import useToggler from '../../hooks/toggler.js'
import useVModel from '../../hooks/vModel.js'
import VehicleCard from '../vehicles/VehicleCard.vue'
import { useRouter } from '../../hooks/router.js'
import { date } from 'quasar'
import { useGetters } from '../../hooks/store.js'
import CustomerContact from '../customers/CustomerContact.vue'

export default {
  name: 'MaintenanceShow',

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  components: {
    CustomerCard,
    FuelLevel,
    OperationsCard,
    MaintenanceStatusBanner,
    TasksCard,
    VehicleCard,
    CustomerContact,
  },

  setup(props, ctx) {
    const router = useRouter()
    const { vModel: maintenance } = useVModel(props.value, ctx)
    const { ongoing, update } = useNotify()
    const {
      toggler: downloading,
      on: startDownload,
      off: stopDownload,
    } = useToggler()
    const { formatDate } = date
    const { getKind } = useGetters('maintenance', ['getKind'])

    const checkbox = {
      mandatory: 'Tassativo',
      show_replaced_spare_parts: 'Richede la visione dei ricambi sostituiti',
      replace_license_plate_frame:
        'Autorizza la sostituzione del porta targa e degli adesivi',
    }

    function customerEdit() {
      router.push({
        name: 'customerEdit',
        params: { id: maintenance.value.customer.id, action: 'edit' },
      })
    }

    function download(spares = false) {
      let notify = ongoing('Generazione in corso')
      startDownload()
      //download
      Maintenances.download(props.value.id, spares)
        .then(() => {
          update(notify)
        })
        .catch(() => {
          update(notify, "Errore durante l'operazione", {
            color: 'negative',
            icon: 'mdi-alert-outline',
          })
        })
        .finally(() => stopDownload())
    }

    function vehicleEdit() {
      router.push({
        name: 'vehicleEdit',
        params: { id: maintenance.value.vehicle.id, action: 'edit' },
      })
    }

    function operationCreate() {
      router.push({
        name: 'maintenanceOperation',
        params: { operation: 'create' },
      })
    }

    function operationShow(operation) {
      router.push({ name: 'maintenanceOperation', params: { operation } })
    }

    return {
      checkbox,
      customerEdit,
      download,
      downloading,
      formatDate,
      getKind,
      maintenance,
      operationCreate,
      operationShow,
      vehicleEdit,
    }
  },
}
</script>
