<template>
  <div>

    <!-- search in all vehicles -->
    <VehicleAutocomplete
      class="q-mt-md"
      v-if="!owner&&!detailShow"
      :value="detail"
      @input="setDetail"
      creable
    />

    <!-- owner vehicle -->
    <CustomerVehicleList
      class="q-mt-md"
      v-if="owner&&!detailShow"
      :customer="owner"
      @create="show('create')"
      @select="setDetail"
    />

    <!-- selected -->
    <VehicleForm
      class="q-mt-md"
      v-if="detailEdit"
      v-model="editable"
      :errors="errors"
    >
      <q-card-actions align="right">

        <BaseBtn
          class="q-px-sm"
          label="Annulla"
          color="black"
          @click="errors.clearError();show()" />

        <BaseBtn
          class="q-px-sm q-ml-lg"
          label="Salva"
          @click="save()" />

      </q-card-actions>
    </VehicleForm>

    <!-- card data -->
    <VehicleBanner
      class="q-mt-md"
      v-if="detailShow&&!detailEdit"
      :value="detail"
    >
      <template #action>
        <BaseBtn
          flat
          color="white"
          label="Aggiorna i dati"
          @click="edit()" />
        <BaseBtn
          flat
          color="white"
          label="Cambia veicolo"
          @click="close()"/>
      </template>
    </VehicleBanner>

  </div>
</template>

<script>
import CustomerVehicleList from '../customers/CustomerVehicleList.vue'
import VehicleAutocomplete from '../vehicles/VehicleAutocomplete.vue'
import VehicleBanner from '../vehicles/VehicleBanner.vue'
import VehicleForm from '../vehicles/VehicleForm.vue'
import Vehicles from '../../apis/vehicles.js'
import useApiCrud from '../../hooks/apiCrud.js'
import { toRefs, watch } from '@vue/composition-api'

export default{

  components: {
    CustomerVehicleList,
    VehicleAutocomplete,
    VehicleBanner,
    VehicleForm,
  },

  props: {
    value :{
      type: Object,
      required: true,
    },

    owner: {
      type: Number,
    }

  },

  setup( props, ctx ){

    const {
      close,
      edit,
      errors,
      save,
      setDetail,
      show,
      state,
    } = useApiCrud({
      ctx,
      api: Vehicles,
      routed: false,
      events : {
        save( response ){
          setDetail( response.data )
          show()
          return false
        }
      }
    })

    watch( () => state.detail, ( value ) => {
      if( !state.detailCreate ){
        ctx.emit( 'input', value )
      }
    })

    //set owner if exist for new vehicle
    watch( () => state.editable, ( value ) => {
      if( value.owner && value.owner.id == null && props.owner ){
        state.editable.owner.id = props.owner
      }
    })

    //set value
    setDetail( props.value )

    return {
      close,
      edit,
      errors,
      save,
      show,
      setDetail,
      ...toRefs( state ),
    }

  },

}
</script>

