import { ref } from '@vue/composition-api'
import { Plugins, CameraDirection, CameraResultType, CameraSource } from "@capacitor/core";

export default function useCamera( options = {} )
{
  const { Camera } = Plugins

  const photo = ref('')

  const takePhoto = async () => {

    try {
      const cameraPhoto = await Camera.getPhoto({
        allowEditing: true,
        direcion: CameraDirection.Rear,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        presentationStyle: 'fullscreen',
        quality : 70,
        webUseInput: true,
        ...options
      })

      photo.value = cameraPhoto
    }
    catch(er){
      //
    }
  }

  return {
    photo,
    takePhoto,
  }
}
