<template>
  <div>
    <q-file
      ref="fileInput"
      class="q-pr-sm"
      :value="value"
      v-bind="{
        label: 'Seleziona file da allegare',
        filled: true,
        append: true,
        clearable: !multiple,
        ...$attrs,
      }"
      v-on="$listeners"
    >
      <template v-slot:prepend>
        <q-icon name="mdi-paperclip" />
      </template>

      <template v-slot:append v-if="camera">
        <BaseBtn icon="mdi-camera" @click="takePhoto" round flat>
          <q-tooltip>Scatta una foto</q-tooltip>
        </BaseBtn>
      </template>
    </q-file>

    <template v-if="multiple">
      <template v-for="(attach, index) in value">
        <q-avatar
          class="q-mr-sm relative-position bg-primary"
          rounded
          size="160px"
          :key="index"
          font-size=".7rem"
        >
          <q-icon
            color="secondary"
            class="absolute-top"
            :name="`mdi-${attach.remove ? 'delete-outline' : 'paperclip'}`"
            style="top: 20px; left: 40px"
            size="80px"
          />

          <q-img
            :src="uri(attach, '160x160')"
            style="height: 160px; width: 160px"
            :class="{ 'attach-removed': attach.remove }"
          >
            <div
              class="absolute-bottom text-center"
              :class="{ 'text-gray-7 text-italic text-strike': attach.remove }"
            >
              {{ attach.name }}
            </div>

            <BaseBtn
              round
              class="absolute-top-right q-ma-sm"
              icon="mdi-close"
              size="xs"
              v-if="!attach.remove"
              @click="remove(index)"
              color="negative"
            >
              <q-tooltip>Rimuovi</q-tooltip>
            </BaseBtn>

            <BaseBtn
              round
              class="absolute-top-right q-ma-sm"
              icon="mdi-undo"
              size="xs"
              v-else
              @click="recovery(index)"
              color="black"
            >
              <q-tooltip>Ripristina</q-tooltip>
            </BaseBtn>
          </q-img>
        </q-avatar>
      </template>
    </template>
  </div>
</template>

<script>
import Attachs from '../../apis/attachs.js'
import useCamera from '../../hooks/camera.js'
import { date } from 'quasar'
import { computed, watch } from '@vue/composition-api'

export default {
  name: 'AttachsForm',
  inheritAttrs: false,

  props: {
    camera: Boolean,
    value: {
      type: [Array, File],
      default: () => [],
    },
  },

  setup(props, { refs, emit, attrs }) {
    const { takePhoto, photo } = useCamera({})

    const multiple = computed(() => attrs.multiple !== undefined)

    watch(
      () => photo.value,
      (value) => {
        if (value) {
          fetch(value.webPath)
            .then((response) => response.blob())
            .then((file) => {
              file.fromCamera = true
              file.webPath = value.webPath
              file.name = `IMG_${date.formatDate(
                new Date(),
                'YYYY_MM_DD_HH-mm_ss'
              )}.${value.format}`
              refs.fileInput.addFiles([file])
            })
        }
      }
    )

    function remove(index) {
      const attachs = [...props.value]

      attachs[index].remove = true

      emit('input', attachs)
    }

    function recovery(index) {
      const attachs = [...props.value]

      attachs[index].remove = false

      emit('input', attachs)
    }

    function uri(attach, size = '') {
      return attach.id ? Attachs.uri(attach.id, size) : attach.webPath || null
    }

    return {
      takePhoto,
      photo,
      recovery,
      remove,
      uri,
      multiple,
    }
  },
}
</script>

<style lang="scss">
.attach-removed {
  > .q-img__image {
    opacity: 0.4;
  }
}
</style>
