<template>

  <BaseSelect
    use-input
    ref="select"
    hide-dropdown-icon
    v-bind="{ label:'Cerca tra i veicoli registrati', ...$attrs}"
    v-on="{ filter, ...$listeners }"
    @input-value="inputValue"
    :value="value"
    :display-value="value.name ? value.name : ''"
    :options="state.data">

    <template v-slot:hint>
      Inserisci numero di targa, numero di telaio o numero di serie
    </template>

    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction:row!important">
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong>"
          </span>
          <BaseBtn
            v-if="creable"
            @click="create"
            label="Crea"
            icon="mdi-account-plus"
          />
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item
        v-bind="scope.item.itemProps"
        v-on="scope.item.itemEvents"
      >
        <q-item-section>
          <q-item-label>{{ scope.item.opt.model.brand.name }} {{ scope.item.opt.model.name }}</q-item-label>
          <q-item-label caption>
            <q-icon name="mdi-calendar" /> Targa: {{ scope.item.opt.plate_number }} | N° Telaio: {{ scope.item.opt.frame_number }} | N° Serie: {{ scope.item.opt.serial_number }}
          </q-item-label>
          <q-item-label v-if="scope.item.opt.model.year" caption>
            <q-icon name="mdi-card-bulleted-outline" /> Anno: {{ scope.item.opt.model.year }}
          </q-item-label>
          <q-item-label v-if="scope.item.opt.owner" caption>
            <q-icon name="mdi-account" /> Proprietario: {{ scope.item.opt.owner.fullname }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

  </BaseSelect>

</template>

<script>
import useApiCrud from '../../hooks/apiCrud.js'
import Vehicles from '../../apis/vehicles.js'
import { ref } from '@vue/composition-api'

export default{
  name: 'VehicleAutocomplete',
  inheritAttrs: false,

  props: {
    creable: Boolean,
    inputPreserveValue: Boolean,
    value: {
      type: Object,
    },
  },

  setup({inputPreserveValue}, ctx ){
    const query = ref('')

    const {
      apiIndex,
      apiShow,
      state,
    } = useApiCrud({
      ctx,
      api: Vehicles,
      routed: false,
    })

    function filter( query, update, abort )
    {
      if( query.length < 2 ){
        abort()
        return
      }

      apiIndex({ query }).then( response => {
        update( response.data )
      })

    }

    function inputValue( value )
    {
      query.value = value

      if( !inputPreserveValue && value.length > 0 ){
        ctx.emit( 'input', {} )
      }
    }

    function create()
    {
      apiShow( 'create' ).then( response => {
        ctx.emit( 'input', response.data )
      })
    }

    return {
      create,
      inputValue,
      filter,
      query,
      state,
    }
  }

}
</script>

