<template>

  <q-toolbar class="q-px-none">

    <q-icon
      name="mdi-filter-variant"
      class="q-mr-sm" />

    <div class="flex-md full-width">

      <div class="flex-md q-mb-sm q-mb-md-none">

        <div class="flex-sm">

          <div class="flex col-grow">

            <BaseSelect
              class="q-pb-none col-grow"
              label="Stato"
              style="min-width:90px"
              dense
              native
              emit-value
              option-value="id"
              option-label="name"
              :clearable="!!status.id"
              clear-icon="close"
              @input="status => setFilter({status})"
              :value="status"
              :display-value="status.id ? status.name : ''"
              :options="statusList" />

            <BaseSelect
              class="q-pb-none q-ml-sm-sm"
              label="Genere"
              style="min-width:100px;"
              dense
              native
              emit-value
              option-value="value"
              option-label="label"
              :value="value.kind"
              @input="kind => setFilter({kind})"
              :display-value="kindLabel"
              :options="kinds" />

          </div>

          <div class="flex">

            <BaseDatePicker
              style="width:160px"
              class="q-pb-none q-ml-sm-sm"
              label="Dal"
              dense
              clearable
              clear-icon="close"
              :value="value.from"
              @input="from => setFilter({from})" />

            <BaseDatePicker
              style="width:160px"
              class="q-pb-none q-ml-sm-sm"
              label="Al"
              dense
              clearable
              clear-icon="close"
              :value="value.to"
              @input="to => setFilter({to})" />

          </div>
        </div>

        <div class="flex-md q-mt-sm q-mt-lg-none col-grow">

          <div class="flex col-grow">

            <CustomerAutocomplete
              class="q-pb-none q-ml-lg-sm col-grow"
              dense
              :clearable="!!customer.id"
              clear-icon="close"
              label="Cliente"
              option-value="id"
              emit-value
              :value="customer"
              @input="customer => setFilter({ customer })" />

            <UserAutocomplete
              class="q-pb-none q-ml-none q-ml-sm-sm col-grow"
              dense
              :clearable="!!user.id"
              clear-icon="close"
              label="Operatore"
              option-value="id"
              emit-value
              :value="user"
              @input="user => setFilter({user})" />

          </div>

          <BaseInput
            class="q-pb-none q-ml-md-sm col-grow q-mt-sm q-mt-md-none"
            label="Cerca"
            debounce="500"
            outline
            dense
            clearable
            clear-icon="close"
            :value="value.query"
            :stack-label="false"
            @input="query => setFilter({query})">

            <template #append>
              <q-icon name="mdi-magnify" />
            </template>

          </BaseInput>

        </div>

      </div>

    </div>

  </q-toolbar>

</template>

<script>
import CustomerAutocomplete from '../customers/CustomerAutocomplete.vue'
import UserAutocomplete from '../users/UserAutocomplete.vue'
import { useGetters, useState } from '../../hooks/store.js'
import { computed } from '@vue/composition-api'

export default{
  name: 'MaintenanceListFilters',

  components: {
    CustomerAutocomplete,
    UserAutocomplete,
  },

  props: {
    value: {
      type: Object,
    },
  },

  setup(props, {emit}){

    const { status : statusList } = useState( 'maintenance', [ 'status' ])
    const { getStatusById } = useGetters( 'maintenance', [ 'getStatusById' ])
    const status = computed( () => props.value.status ? getStatusById( props.value.status ).value : { id: null } )

    const { collection : usersList } = useGetters( 'user', [ 'collection' ])
    const { getUserById } = useGetters( 'user', [ 'getUserById' ])
    const user = computed( () => props.value.user ? getUserById( props.value.user ).value : { id: null } )

    const { kinds : kindsList } = useState( 'maintenance', [ 'kinds' ])
    const kinds = computed( () => [ { value : '',  label: 'Tutti' } ].concat( kindsList.value ) )
    const kindLabel = computed( () => kinds.value.find( kind => '' + props.value.kind == '' + kind.value ).label || 'Tutti' )

    const customer = computed( () => props.value.customer ? { id: props.value.customer } : { id: null } )

    function setFilter( value ){
      emit( 'input', { ...props.value, ...value } )
    }

    return {
      customer,
      setFilter,
      kinds,
      kindLabel,
      status,
      statusList,
      user,
      usersList,
    }
  },

}
</script>

