<template>
  <BaseCard
    multi-section
    :title="title"
    :subtitle="subtitle"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <q-list dense class="q-pb-md">
      <template v-for="(section, name) in sections">
        <q-item :key="`maintenane_label${name}`" class="q-mx-sm q-my-xs">
          <q-item-section avatar>
            <q-icon color="primary" :name="section.icon" />
          </q-item-section>

          <q-item-section>
            <div class="flex items-center">
              <span v-html="sectionValue(name)" />
              <q-item-label v-if="!!section.label" caption class="q-ml-sm">
                <q-icon
                  name="fiber_manual_record"
                  style="font-size: 6px"
                  class="q-mr-xs"
                />
                {{ section.label }}
              </q-item-label>

              <CustomerContact
                class="q-ml-md"
                size="sm"
                dense
                v-if="name == 'customer'"
                :customer="maintenance.customer"
              />
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>

    <template #header-action>
      <StatusBadge
        :value="maintenance.status.id"
        class="q-ml-auto q-mt-sm q-px-md"
      />
    </template>
  </BaseCard>
</template>

<script>
import StatusBadge from './StatusBadge.vue'
import CustomerContact from '../customers/CustomerContact.vue'

export default {
  name: 'MaintenanceCard',

  components: {
    StatusBadge,
    CustomerContact,
  },

  props: {
    maintenance: Object,

    subtitle: {
      type: String,
      defaul: null,
    },

    title: {
      type: String,
      default: 'Scheda manutenzione',
    },
  },

  setup(props) {
    const sections = {
      customer: {
        icon: 'mdi-account-circle-outline',
        value: () => props.maintenance.customer.fullname || '-',
      },
      vehicle: {
        icon: 'mdi-rv-truck',
        value: (value) =>
          `${value.model.brand.name || ''} - ${value.model.name || ''} ${
            value.model.year || ''
          }`,
      },
      plate_number: {
        icon: 'money',
        label: 'Targa',
        value: () => props.maintenance.vehicle.plate_number,
      },
    }

    function sectionValue(name) {
      if (sections[name].value == undefined)
        return props.maintenance[name] || '-'

      return sections[name].value(props.maintenance[name])
    }

    return {
      sections,
      sectionValue,
    }
  },
}
</script>
