<template>
  <BaseSelect
    ref="select"
    v-bind="{ clearable : isClearable, ...attrs, ...$attrs}"
    v-on="{ clear, ...$listeners }"
    :value="value"
    :errors="errors"
    :display-value="value.title ? value.title : ''">

    <template v-slot:option="scope">
      <q-item
        v-bind="scope.item.itemProps"
        v-on="scope.item.itemEvents"
      >
        <q-item-section>
          <q-item-label>
            {{ scope.item.opt.title }}
          </q-item-label>
          <q-item-label v-if="scope.item.opt.description" caption>
            {{ scope.item.opt.description }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>

  </BaseSelect>
</template>

<script>
import mapValues from 'lodash/mapValues'
import { computed } from '@vue/composition-api'

export default {
  name: 'TaskSelect',
  inheritAttrs: false,

  props: {
    clearable: Boolean,
    options: {
      type: Array,
      default: () => [],
    },

    errors: {
      type: Object,
    },

    value : {
      type: Object,
    },

  },

  setup(props,{emit}){
    const isClearable = computed( () => props.clearable && props.value.id != null )

    const attrs = {
      label:'Anomalia',
      options: props.options, 
    }

    function clear( value )
    {
      emit('input', mapValues( { ...value }, () => null ) )
    }

    return {
      attrs,
      isClearable,
      clear,
    }
  }

}
</script>

