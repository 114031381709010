<template>
  <div>
    <BaseCard
      header="Modifica scheda"
      title="Stato"
      multi-section
      subtitle="modifica lo stato della scheda"
    >
      <div class="flex-md items-center">
        <MaintenanceStatusBanner
          class="q-pb-lg"
          style="max-width: 430px"
          hide-info
          v-model="maintenance.status"
          :history="maintenance.statusHistory"
        />

        <div class="q-pb-lg q-px-md">
          <BaseBtn
            class="q-pr-md q-mr-md"
            label="Salva"
            color="pink"
            icon="mdi-content-save-outline"
            :disabled="!statusChanged"
            @click="$emit('update-status', maintenance.status)"
          />

          <BaseBtn
            class="q-px-md"
            label="Annulla"
            color="black"
            v-if="statusChanged"
            @click="$emit('undo-status')"
          />
        </div>
      </div>

      <div class="flex-md">
        <div class="q-mx-md flex column">
          <BaseCheckbox
            label="DFS - Da fare subito"
            error-name="do_immediatly"
            dense
            :errors="errors"
            v-model="maintenance.do_immediatly"
          >
            <q-tooltip>Da fare subito</q-tooltip>
          </BaseCheckbox>

          <BaseCheckbox
            class="q-mt-sm"
            label="Tassativo"
            error-name="mandatory"
            dense
            :errors="errors"
            v-model="maintenance.mandatory"
          >
            <q-tooltip>Da fare subito</q-tooltip>
          </BaseCheckbox>
        </div>

        <BaseDateTimePicker
          style="max-width: 250px"
          class="q-mx-md"
          label="Data e ora di consegna"
          error-name="delivery_date"
          :errors="errors"
          v-model="maintenance.delivery_date"
        />
      </div>
    </BaseCard>

    <BaseCard title="Consulente">
      <UserAutocomplete
        label="Consulente"
        error-name="user_id"
        :errors="errors"
        v-model="maintenance.user"
      />
    </BaseCard>

    <BaseCard title="Dati registrazione" :subtitle="subtitle">
      <MaintenanceStepInfo
        class="q-my-sm"
        v-model="maintenance"
        :errors="errors"
      />
    </BaseCard>

    <BaseCard title="Anomalie segnalate">
      <MaintenanceTasks class="q-my-sm" v-model="maintenance.tasks" />
    </BaseCard>

    <q-card-section align="center">
      <!-- default slot -->
      <slot />
    </q-card-section>
  </div>
</template>

<script>
import MaintenanceStepInfo from "./MaintenanceStepInfo.vue";
import MaintenanceStatusBanner from "./MaintenanceStatusBanner.vue";
import MaintenanceTasks from "./MaintenanceTasks.vue";
import UserAutocomplete from "../users/UserAutocomplete.vue";
import useVModel from "../../hooks/vModel.js";

export default {
  components: {
    MaintenanceStatusBanner,
    MaintenanceStepInfo,
    MaintenanceTasks,
    UserAutocomplete,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
    },
    statusChanged: Boolean,
  },

  setup(props, ctx) {
    const { vModel: maintenance } = useVModel(props.value, ctx);

    const subtitle = `${maintenance.value.customer.fullname} -
      ${maintenance.value.vehicle.model.brand.name}
    ${maintenance.value.vehicle.model.name}
    ${maintenance.value.vehicle.model.year}`;

    return {
      maintenance,
      subtitle,
    };
  },
};
</script>
