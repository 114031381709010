<template>
  <q-badge :color="status.color" :label="status.name" />
</template>

<script>
import { useGetters } from '../../hooks/store.js'
import { computed } from '@vue/composition-api'

export default{

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  setup(props){
    const { getStatusById } = useGetters( 'maintenance', [ 'getStatusById' ] )

    return {
      status: computed( () => {
        return getStatusById( props.value ).value
      })
    }
  },

}
</script>

