<template>
  <div>

    <h6 class="q-mb-sm q-mt-md">Aggiungi un anomalia</h6>

    <q-banner rounded class="bg-grey-3 q-pt-lg q-pb-xl q-mb-md shadow-1">
      <TaskForm class="q-gutter-sm q-pt-md" @submit="add" />
    </q-banner>

    <h6 class="q-mb-sm q-mt-lg">Segnalazioni Create</h6>

    <BaseBanner
      v-if="value.length == 0"
      message="Nessuna segnalazione inserita" />

    <MaintenanceTasks
      class="q-my-sm"
      disable-create
      v-else
      :value="value"
      @input="value => $emit( 'input', value )"
    />

  </div>
</template>

<script>
import MaintenanceTasks from './MaintenanceTasks.vue'
import TaskForm from '../tasks/TaskForm.vue'

export default{

  components: {
    MaintenanceTasks,
    TaskForm,
  },

  props: {
    value :{
      type: Array,
      required: true,
    },
    errors : {
      type: Object,
    },
  },

  setup( props, { emit } ){

    function add( task )
    {
      let tasks = props.value

      tasks.push( task )

      emit( 'input', tasks )
    }

    return {
      add,
    }

  },

}
</script>
