<template>
  <div class="row q-mt-lg">
    <div class="col-12 col-md-4 q-pr-md-lg">
      <DetailCard :operation="operation">
        <slot />
      </DetailCard>
    </div>

    <div class="col-12 col-md-8">
      <div class="row">
        <div class="col-12">
          <BaseCard title="Descrizione">
            {{ operation.description }}
          </BaseCard>
        </div>

        <div class="col-12">
          <BaseCard title="Parti di ricambio">
            <SparePartsTable
              :data="operation.spare_parts"
              v-if="
                operation.spare_parts != undefined &&
                operation.spare_parts.length
              "
            />
            <BaseBanner
              v-else
              icon="mdi-information-outline"
              message="Nessun ricambio utilizzato"
            />
          </BaseCard>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="operation.attachs.length">
      <BaseCard title="Allegati">
        <AttachsList :attachs="operation.attachs" />

        <template #header-action>
          <BaseBtn
            v-if="operation.attachs.length"
            @click="downloadAttachs"
            class="q-ml-auto"
            label="Scarica Zip"
            icon="mdi-package-down"
          />
        </template>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import Attachs from '../../apis/attachs.js'
import AttachsList from '../attachs/AttachsList.vue'
import SparePartsTable from '../spare-parts/SparePartsTable.vue'
import useNotify from '../../hooks/notify.js'
import DetailCard from './DetailCard.vue'

export default {
  components: {
    AttachsList,
    SparePartsTable,
    DetailCard,
  },

  props: {
    operation: {
      type: Object,
    },
  },

  setup(props) {
    const { download } = Attachs
    const { ongoing, update } = useNotify()

    function downloadAttachs() {
      if (props.operation.attachs.length) {
        let notify = ongoing('Generazione in corso')

        const attachs = [...props.operation.attachs]

        download(attachs.map((attach) => attach.id))
          .then(() => {
            update(notify)
          })
          .catch(() => {
            update(notify, "Errore durante l'operazione", {
              color: 'negative',
              icon: 'mdi-alert-outline',
            })
          })
      }
    }

    return {
      downloadAttachs,
    }
  },
}
</script>
