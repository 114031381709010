<template>
  <div>
    <Container
      v-if="value.length > 0"
      @onDrop="onDrop"
      :drag-handle-selector="'.mdi-drag'"
      :drag-class="'shadow-5'"
    >
      <Draggable
        v-for="(item, index) in value"
        :key="`task_draggable_${index}`"
      >
        <div class="draggable-item">
          <q-card class="q-mb-sm q-py-sm bg-grey-3" flat bordered>
            <q-item v-if="!inEditing || editing.index != index">
              <q-item-section side>
                <q-icon
                  name="mdi-drag"
                  style="cursor: move"
                  v-if="!inEditing && !item.remove"
                >
                  <q-tooltip>Ordina</q-tooltip>
                </q-icon>
              </q-item-section>

              <q-item-section
                :class="{ 'text-gray-7 text-italic text-strike': item.remove }"
              >
                <q-item-label>{{ item.title }}</q-item-label>
                <q-item-label caption>{{ item.description }}</q-item-label>

                <q-item-label v-if="item.attachs.length" class="flex">
                  <q-chip
                    class="q-ml-none"
                    clickable
                    v-for="(attach, attachIndex) in item.attachs"
                    @click="attachShow(attach)"
                    :key="`attach-${attachIndex}`"
                  >
                    <q-avatar color="primary">
                      <q-icon name="mdi-paperclip" color="white" />
                    </q-avatar>
                    <span
                      :class="{
                        'text-gray-7 text-italic text-strike': attach.remove,
                      }"
                    >
                      {{ attach.name }}
                    </span>
                  </q-chip>
                </q-item-label>
              </q-item-section>

              <TaskPlanning :task="item" v-if="canManage" />

              <q-item-section side top>
                <q-item-label
                  caption
                  :class="`text-${getPriorityColor(item.priority).value}`"
                >
                  <q-icon
                    name="mdi-information-outline"
                    style="margin-top: -2px"
                  />
                  priorità
                  {{ getPriorityLabel(item.priority).value.toLowerCase() }}
                </q-item-label>

                <template v-if="!item.remove">
                  <template v-if="!inEditing">
                    <BaseBtn
                      size="sm"
                      class="q-mt-md"
                      label="Modifica"
                      @click="edit(index)"
                    />

                    <BaseBtn
                      size="sm"
                      color="negative"
                      class="q-mt-sm"
                      label="Elimina"
                      @click="remove(index)"
                    />
                  </template>
                </template>

                <BaseBtn
                  v-else
                  class="q-mt-md"
                  size="sm"
                  label="Ripristina"
                  color="black"
                  @click="recovery(index)"
                />
              </q-item-section>
            </q-item>

            <TaskForm
              updating
              class="q-pa-md"
              @close="editing = {}"
              @submit="save"
              v-else
              v-model="editing"
            />
          </q-card>
        </div>
      </Draggable>
    </Container>

    <template v-if="!disableCreate">
      <q-card
        class="q-mb-sm q-py-sm bg-grey-3"
        flat
        bordered
        v-if="editing.id == 'new'"
      >
        <TaskForm
          updating
          class="q-pa-md"
          @close="editing = {}"
          @submit="save"
          v-model="editing"
        />
      </q-card>

      <BaseBtn
        v-if="!inEditing"
        @click="add"
        class="q-mt-sm"
        label="Aggiungi nuova"
        icon="mdi-plus"
      />
    </template>

    <AttachModal :attach="attach" @hide="attach = {}" />
  </div>
</template>

<script>
import AttachModal from '../attachs/AttachModal.vue'
import TaskForm from '../tasks/TaskForm.vue'
import { Container, Draggable } from 'vue-smooth-dnd'
import { useGetters } from '../../hooks/store.js'
import { ref, computed } from '@vue/composition-api'
import TaskPlanning from '../tasks/TaskPlanning.vue'

export default {
  components: {
    AttachModal,
    Container,
    Draggable,
    TaskForm,
    TaskPlanning,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    errors: {
      type: Object,
    },

    disableCreate: Boolean,
  },

  setup(props, { emit, root }) {
    const { getPriorityColor, getPriorityLabel } = useGetters('task', [
      'getPriorityColor',
      'getPriorityLabel',
    ])

    const { userCan } = useGetters('auth', ['userCan'])

    const attach = ref({})
    const editing = ref({})
    const inEditing = computed(() => editing.value.index !== undefined)

    function add() {
      editing.value = { id: 'new' }
    }

    function edit(index) {
      editing.value = Object.assign({}, props.value[index], { index: index })
    }

    function remove(index) {
      const tasks = props.value

      root.$set(tasks[index], 'remove', true)

      emit('input', tasks)
    }

    function recovery(index) {
      const tasks = props.value

      root.$set(tasks[index], 'remove', false)

      emit('input', tasks)
    }

    function save(task) {
      const index = task.index
      delete task.index

      const tasks = props.value

      if (task.id != 'new') {
        tasks.splice(index, 1, Object.assign({}, task))
      } else {
        //random id
        task.id = `new_${new Date().getTime()}`
        tasks.push(task)
      }

      emit('input', tasks)

      editing.value = {}
    }

    function onDrop({ removedIndex, addedIndex }) {
      const tasks = props.value
      //remove from old position
      let task = tasks.splice(removedIndex, 1).pop()
      //insert in new position
      tasks.splice(addedIndex, 0, task)

      emit('input', tasks)
    }

    function attachShow(item) {
      attach.value = { ...item }
    }

    const canManage = computed(() => userCan('tasks_manage').value)

    return {
      add,
      attach,
      attachShow,
      canManage,
      edit,
      editing,
      getPriorityColor,
      getPriorityLabel,
      inEditing,
      onDrop,
      recovery,
      remove,
      save,
    }
  },
}
</script>
