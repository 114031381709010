<template>
  <BaseSelect
    use-input
    ref="select"
    hide-dropdown-icon
    v-bind="{ label: 'Cerca tra i ricambi', ...$attrs }"
    v-on="{ filter, ...$listeners }"
    @input-value="inputValue"
    :value="value"
    :options="state.data"
    :display-value="displayValue"
  >
    <template v-slot:hint v-if="!value.id">
      Inserisci il codice / nome del ricambio da cercare
    </template>

    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction: row !important"
        >
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong
            >"
          </span>
          <BaseBtn
            v-if="creable"
            @click="create"
            label="Crea"
            icon="mdi-account-plus"
          />
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.item.itemProps" v-on="scope.item.itemEvents">
        <q-item-section side v-if="freeBrand">
          <q-item-label>
            <q-tooltip>Produttore</q-tooltip>
            {{ scope.item.opt.brand.name }}
          </q-item-label>
        </q-item-section>

        <q-item-section>
          <q-item-label
            >Cod. {{ scope.item.opt.code }} -
            {{ scope.item.opt.name }}</q-item-label
          >

          <q-item-label caption>
            {{ scope.item.opt.description }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </BaseSelect>
</template>

<script>
import useApiCrud from '../../hooks/apiCrud.js'
import SpareParts from '../../apis/spareParts.js'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'SparePartAutocomplete',
  inheritAttrs: false,

  props: {
    creable: Boolean,
    inputPreserveValue: Boolean,
    value: {
      type: Object,
    },
    freeBrand: Boolean,
  },

  setup(props, ctx) {
    const query = ref('')

    const { apiIndex, apiShow, state } = useApiCrud({
      ctx,
      api: SpareParts,
      routed: false,
    })

    function filter(query, update, abort) {
      if (!query.length) {
        abort()
        return
      }

      const brand = !props.freeBrand ? props.value.brand.id : null

      apiIndex({ query, brand }).then((response) => {
        update(response.data)
      })
    }

    function inputValue(value) {
      query.value = value

      if (!props.inputPreserveValue && value.length > 0) {
        ctx.emit('input', {})
      }
    }

    function create() {
      apiShow('create').then((response) => {
        //cler input
        ctx.refs.select.$refs.select.updateInputValue('')
        //select
        ctx.emit('input', {
          ...response.data,
          code: query.value,
          brand: { ...response.data.brand, ...props.value.brand },
        })
      })
    }

    const displayValue = computed(() => {
      if (!props.value.code) return ''

      let value = ''

      if (props.freeBrand) {
        value += `${props.value.brand.name} - `
      }

      value += `Cod. ${props.value.code} - ${props.value.name ?? ''}`

      return value
    })

    return {
      create,
      displayValue,
      filter,
      inputValue,
      query,
      state,
    }
  },
}
</script>
