<template>
  <q-field
    :style="{ maxWidth: ( small ? 200 : 360 ) + 'px' }"
    borderless
    :label="hideLabel ? null : 'Livello carburate (%)'"
    stack-label>
    <template v-slot:control>
      <q-slider
        class="q-mx-sm q-mt-lg"
        label-always
        color="secondary"
        v-if="!readonly"
        v-model="level"
        :min="0"
        :max="100" />

      <div class="fuel_level">
        <img src="../../assets/fuel.png" />
        <div class="level" :style="style" />
      </div>
    </template>
  </q-field>
</template>

<script>
import useVModel from '../../hooks/vModel.js'
import { computed } from '@vue/composition-api'

export default{

  props: {
    value :{
      type: Number,
      default: 0,
    },
    hideLabel: Boolean,
    readonly: Boolean,
    small: Boolean,
  },

  setup( props, ctx ){
    const { vModel : level } = useVModel( props.value, ctx )

    const style = computed( () => {
      const transform = 'rotate( ' + ( props.value - 230 ) + 'deg )'
      const transformOrigin = '0 0'
      const top = props.small ? '79%' : '93%'
      const width = props.small ? '6px' : '8px'

      return { transform, transformOrigin, top, width }
    })

    return {
      level,
      style,
    }
  }
}
</script>

<style lang="scss">
.fuel_level {
  position:reative;
  max-width:100%;
  overflow:hidden;
  > img {
    border:1px solid #ededed;
    max-width:100%;
    margin-top:1rem;
    vertical-align:bottom;
  }

  > .level {
    position:absolute;
    left:50%;
    height:50%;
    background:#c00;

    &::after {
      content:'';
      border-radius:50%;
      height:24px;
      width:24px;
      background:inherit;
      position:absolute;
      top:-6px;
      right:-8px;
    }
  }
}
</style>

