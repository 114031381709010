<template>
  <q-item
    class="q-py-md q-px-lg"
    clickable
    @click="$emit( 'click', operation.id )">

    <q-item-section>

      <q-item-label overline>
        <q-icon name="mdi-tag" />
        {{ operation.type.name || '-' }}
      </q-item-label>

      <q-item-label caption>
        {{ operation.description }}
      </q-item-label>

    </q-item-section>

    <q-item-section side top>

      <q-item-label caption>

        <p class="q-mb-sm">
          <q-icon name="mdi-calendar" />
          {{ formatDate( operation.created_at, 'DD/MM/YYYY' ) }}
        </p>

        <p class="q-mb-sm">
          <q-icon name="mdi-tools" />
          {{ operation.user.name }}
        </p>

        <p class="q-mb-sm">
          <q-icon name="mdi-timer-outline" />
          {{ operation.duration*1 }} ore
        </p>

      </q-item-label>

    </q-item-section>

  </q-item>
</template>

<script>
import { date } from 'quasar'
export default{

  props: {
    operation: {
      type: Object,
    },
  },

  setup(){
    return {
      formatDate : date.formatDate,
    }
  }
}
</script>

