<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-4 col-md-7 q-pr-md-lg">
        <TaskSelect
          clearable
          label="Anomalia di riferimento"
          error-name="task_id"
          v-model="operation.task"
          :errors="errors"
          :disable="tasks.length == 0"
          :options="tasks"
        />
      </div>

      <div class="col-12 col-lg-3 col-md-5 q-pr-lg-lg">
        <UserAutocomplete
          label="Operatore"
          error-name="user_id"
          :errors="errors"
          v-model.number="operation.user"
        />
      </div>

      <div class="col-12 col-lg-3 col-md-8 q-pr-md-lg">
        <OperationTypeAutocomplete
          label="Tipo operazione"
          error-name="type_id"
          :creable="canCreate()"
          :errors="errors"
          v-model="operation.type"
        />
      </div>

      <div class="col-12 col-lg-2 col-md-4">
        <BaseInput
          label="Durata (ore)"
          type="number"
          min="0"
          step=".5"
          error-name="duration"
          :errors="errors"
          v-model.number="operation.duration"
        />
      </div>

      <div class="col-12 q-pt-sm">
        <BaseInput
          label="Descrizione"
          type="textarea"
          error-name="description"
          :errors="errors"
          v-model="operation.description"
        />
      </div>

      <div class="col-12 q-mt-sm">
        <AttachsForm
          v-model="operation.attachs"
          camera
          counter
          multiple
          class="q-mb-lg"
          color="secondary"
        />
      </div>
    </div>

    <!-- default slot -->
    <slot />
  </div>
</template>

<script>
import AttachsForm from '../attachs/AttachsForm.vue'
import OperationTypeAutocomplete from './OperationTypeAutocomplete.vue'
import TaskSelect from '../tasks/TaskSelect.vue'
import UserAutocomplete from '../users/UserAutocomplete.vue'
import useVModel from '../../hooks/vModel.js'
import { useGetters } from '../../hooks/store.js'

export default {
  name: 'OperationForm',

  components: {
    AttachsForm,
    OperationTypeAutocomplete,
    TaskSelect,
    UserAutocomplete,
  },

  props: {
    value: {
      type: Object,
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: operation } = useVModel(props.value, ctx)
    const { userCan } = useGetters('auth', ['userCan'])

    function canCreate() {
      return userCan('operation_type_create').value
    }

    return {
      canCreate,
      operation,
    }
  },
}
</script>
