<template>
  <div>
    <p class="text-subtitle2 q-my-none q-pb-sm">Pianificazione</p>

    <div class="row">
      <div class="col-12 col-lg-3 col-md-5 q-pr-md-lg">
        <UserAutocomplete
          hide-bottom-space
          label="Operatore"
          v-model.number="task.user"
        />
      </div>

      <div class="col-12 col-lg-2 col-md-4 q-pr-md-lg">
        <BaseInput
          label="Durata (ore)"
          hide-bottom-space
          type="number"
          min="0"
          step=".05"
          v-model.number="task.total_time"
        />
      </div>

      <div class="col-12 col-lg-2 col-md-3">
        <BaseDatePicker
          hide-bottom-space
          style="max-width: 160px"
          label="Data esecuzione"
          v-model="task.executed_at"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useVModel from '../../hooks/vModel'
import UserAutocomplete from '../users/UserAutocomplete.vue'

export default {
  name: 'TaskPlanningForm',

  components: {
    UserAutocomplete,
  },

  props: {
    value: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: task } = useVModel(props.value, ctx)

    return {
      task,
    }
  },
}
</script>
