<template>
  <q-banner
    rounded
    class="bg-menu text-white"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <div class="text-h6 q-mb-sm">{{ value.model.brand.name }} {{ value.model.name }}</div>

    <p class="q-mb-xs">Anno: {{ value.model.year }}</p>
    <p class="q-mb-xs">N° Targa: {{ value.plate_number || '-' }}</p>
    <p class="q-mb-xs">N° Telaio: {{ value.frame_number || '-' }}</p>
    <p class="q-mb-xs">N° Serie: {{ value.serial_number || '-' }}</p>

  </q-banner>
</template>

<script>
export default{
  name: 'VehicleBanner',

  props: {
    value: Object,
  },

}
</script>

