<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="flex">
        <BaseDateTimePicker
          style="max-width: 230px"
          class="q-mr-lg"
          label="Data e ora di arrivo"
          error-name="registration_date"
          :errors="errors"
          v-model="maintenance.registration_date"
        />

        <BaseInput
          style="max-width: 85px"
          class="q-mr-lg"
          label="N° mezzo"
          error-name="cup_number"
          :errors="errors"
          v-model.number="maintenance.cup_number"
        />

        <BaseSelect
          label="Genere"
          style="width: 110px; max-width: 110px"
          native
          v-model="maintenance.kind"
          emit-value
          option-value="value"
          option-label="label"
          :display-value="getKind(maintenance.kind || 0).value.label"
          :options="kinds"
        />
      </div>

      <div class="flex items-start">
        <BaseInput
          class="q-mr-lg col-grow"
          :label="isNew ? 'Chilometraggio attuale del veicolo' : 'Chilometraggio al momento delle operazioni'"
          error-name="mileage"
          :errors="errors"
          v-model.number="maintenance.mileage"
        />
        <BaseBanner
          class="q-mr-lg"
          v-if="!maintenance.mileage && maintenance.vehicle.mileage"
          color="warning"
          small
          inline-actions
          icon="mdi-chat-question-outline"
        >
          Ultimo valore registrato:
          <b>{{ maintenance.vehicle.mileage }}km</b>
          <template v-slot:action>
            <q-btn
              flat
              color="white"
              label="Copia"
              @click="maintenance.mileage = maintenance.vehicle.mileage"
            >
              <q-tooltip>Usa l'ultimo valore registrato</q-tooltip>
            </q-btn>
          </template>
        </BaseBanner>
      </div>

      <div class="row">
        <div class="col-12">
          <BaseCheckbox
            label="DFS - Da fare subito"
            v-model="maintenance.do_immediatly"
          >
            <q-tooltip>Da fare subito</q-tooltip>
          </BaseCheckbox>
        </div>

        <div class="col-12">
          <BaseCheckbox label="Tassativo" v-model="maintenance.mandatory">
            <q-tooltip>Tassativo</q-tooltip>
          </BaseCheckbox>
        </div>

        <div class="col-12">
          <BaseCheckbox
            label="Richede la visione dei ricambi sostituiti"
            v-model="maintenance.show_replaced_spare_parts"
          />
        </div>

        <div class="col-12">
          <BaseCheckbox
            label="Autorizza la sostituzione del porta targa e degli adesivi"
            v-model="maintenance.replace_license_plate_frame"
          />
        </div>
        <div class="col-12 q-pr-md-lg q-pt-md">
          <BaseInput label="Note" type="textarea" v-model="maintenance.info" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <FuelLevel v-model="maintenance.fuel_level" />
    </div>
  </div>
</template>

<script>
import FuelLevel from './FuelLevel.vue'
import useVModel from '../../hooks/vModel.js'
import { useGetters, useState } from '../../hooks/store.js'
import { watch } from '@vue/composition-api'

export default {
  components: {
    FuelLevel,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
    },
    isNew: {
      type: Boolean,
    }
  },

  setup(props, ctx) {
    const { vModel: maintenance } = useVModel(props.value, ctx)
    const { kinds } = useState('maintenance', ['kinds'])
    const { getKind } = useGetters('maintenance', ['getKind'])

    //do immediatly set also the delivery_date
    watch(
      () => maintenance.value.do_immediatly,
      (value) => {
        if (value && maintenance.value.delivery_date == null) {
          maintenance.value.delivery_date = maintenance.value.registration_date
        }
      }
    )

    return {
      getKind,
      kinds,
      maintenance,
    }
  },
}
</script>
