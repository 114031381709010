<template>
  <div>

    <!-- search -->
    <CustomerAutocomplete
      class="q-mt-md"
      v-if="!detailShow"
      :value="detail"
      @input="( value ) => setDetail( value )"
      creable
    />

    <!-- selected -->
    <CustomerForm
      class="q-mt-md"
      v-if="detailEdit"
      v-model="editable"
      :errors="errors"
    >
      <q-card-actions align="right">

        <BaseBtn
          class="q-px-sm"
          label="Annulla"
          color="black"
          @click="errors.clearError();show()" />

        <BaseBtn
          class="q-px-sm q-ml-lg"
          label="Salva"
          @click="save()" />

      </q-card-actions>
    </CustomerForm>

    <!-- card data -->
    <CustomerBanner
      class="q-mt-md"
      v-if="detailShow&&!detailEdit"
      :customer="detail"
    >
      <template #action>
        <BaseBtn
          flat
          color="white"
          label="Aggiorna i dati"
          @click="edit()" />
        <BaseBtn
          flat
          color="white"
          label="Cambia cliente"
          @click="close()"/>
      </template>
    </CustomerBanner>

  </div>
</template>

<script>
import CustomerAutocomplete from '../customers/CustomerAutocomplete.vue'
import CustomerBanner from '../customers/CustomerBanner.vue'
import CustomerForm from '../customers/CustomerForm.vue'
import Customers from '../../apis/customers.js'
import useApiCrud from '../../hooks/apiCrud.js'
import { toRefs, watch } from '@vue/composition-api'

export default{

  components: {
    CustomerAutocomplete,
    CustomerBanner,
    CustomerForm,
  },

  props: {
    //customer object
    value :{
      type: Object,
      required: true,
    },

  },

  setup( props, ctx ){

    const {
      close,
      edit,
      errors,
      save,
      setDetail,
      show,
      state,
    } = useApiCrud({
      ctx,
      api: Customers,
      routed: false,
      events : {
        save( response ){
          setDetail( response.data )
          show()
          return false
        }
      }
    })

    watch( () => state.detail, ( value ) => {
      if( !state.detailCreate ){
        ctx.emit( 'input', value )
      }
    })

    //set value
    setDetail( props.value )

    return {
      close,
      edit,
      errors,
      save,
      show,
      setDetail,
      ...toRefs( state ),
    }

  },

}
</script>

