<template>
  <q-card flat bordered>
    <q-item>
      <q-item-section
        :class="{ 'text-gray-7 text-italic text-strike': !!sparePart.remove }"
      >
        <q-item-label overline> Cod. {{ sparePart.code }} </q-item-label>

        <q-item-label>
          {{ sparePart.name }}
        </q-item-label>

        <q-item-label caption>
          Quantità: {{ sparePart.quantity }}
          <br />
          Prezzo unitario: {{ formatMoney(sparePart.price) }}
        </q-item-label>
      </q-item-section>

      <q-item-section side top v-if="!readonly">
        <template v-if="!sparePart.remove">
          <BaseBtn
            size="sm"
            class="q-mt-md"
            label="Modifica"
            @click="$emit('edit', sparePart)"
          />

          <BaseBtn
            size="sm"
            color="negative"
            class="q-mt-sm"
            label="Elimina"
            @click="$emit('remove', sparePart)"
          />
        </template>

        <BaseBtn
          v-else
          class="q-mt-md"
          size="sm"
          label="Ripristina"
          color="black"
          @click="$emit('recovery', sparePart)"
        />
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script>
import { formatMoney } from '../../hooks/helpers.js'

export default {
  name: 'OperationSparePartItem',

  props: {
    sparePart: {
      type: Object,
      required: true,
    },

    readonly: Boolean,
  },

  setup() {
    return {
      formatMoney,
    }
  },
}
</script>
