<template>

  <BaseCard
    class="q-mt-md"
    header="Nuova scheda manutenzione"
  >

    <q-stepper
      v-model="step"
      vertical
      ref="stepper"
      flat
      header-nav
      animated
    >

      <!-- customer -->
      <q-step
        title="Dati del cliente"
        :name="1"
        :done="step > 1"
        :error="hasStepError(1)"
        :caption="customerCaption"
        active-icon="mdi-account-outline"
      >
        <MaintenanceStepCustomer
          v-model="maintenance.customer"
        />

        <q-stepper-navigation>
          <BaseBtn
            label="Passa ai dati del veicolo"
            icon="mdi-rv-truck"
            :disabled="!hasCustomer"
            @click="$refs.stepper.next()"
          />
        </q-stepper-navigation>

      </q-step>

      <!-- vehicle -->
      <q-step
        title="Dati del veicolo"
        :name="2"
        :done="step > 2"
        :error="hasStepError(2)"
        :caption="vehicleCaption"
        icon="mdi-rv-truck"
        active-icon="mdi-rv-truck"
      >
        <MaintenanceStepVehicle
          v-model="maintenance.vehicle"
          :owner="hasCustomer ? maintenance.customer.id : null"
        />

        <q-stepper-navigation>
          <BaseBtn
            label="Passa ai dati di registrazione"
            icon="mdi-car-info"
            :disabled="!hasVehicle"
            @click="$refs.stepper.next()"
          />
        </q-stepper-navigation>
      </q-step>

      <!-- vehicle -->
      <q-step
        title="Dati di registrazione"
        :name="3"
        :done="step > 3"
        caption="Informazioni di registrazione"
        icon="mdi-car-info"
        active-icon="mdi-car-info"
      >

        <MaintenanceStepInfo v-model="maintenance" :errors="errors" is-new />

        <q-stepper-navigation>
          <BaseBtn
            label="Passa alla compilazione delle anomalie"
            icon="mdi-format-list-bulleted"
            @click="$refs.stepper.next()"
          />
        </q-stepper-navigation>

      </q-step>

      <!-- tasks -->
      <q-step
        :name="4"
        :done="step > 4"
        title="Descrizione delle anomalie denunciate dal cliente"
        icon="mdi-format-list-bulleted"
        active-icon="mdi-format-list-bulleted"
      >

        <MaintenanceStepTasks v-model="maintenance.tasks" />

      </q-step>

    </q-stepper>

    <q-stepper-navigation align="center" class="q-mt-none q-mb-lg">
      <BaseBtn
        color="pink"
        label="Salva la scheda"
        icon="mdi-content-save-outline"
        :disable="!hasCustomer||!hasVehicle"
        @click="$emit('submit')"
      />
    </q-stepper-navigation>

  </BaseCard>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import MaintenanceStepCustomer from '../maintenances/MaintenanceStepCustomer.vue';
import MaintenanceStepInfo from '../maintenances/MaintenanceStepInfo.vue';
import MaintenanceStepTasks from '../maintenances/MaintenanceStepTasks.vue';
import MaintenanceStepVehicle from '../maintenances/MaintenanceStepVehicle.vue';
import useNotify from '../../hooks/notify.js'
import useVModel from '../../hooks/vModel.js'
import { computed, ref, watch } from '@vue/composition-api'

export default{
  name : 'MaintenanceCreateStepper',

  components: {
    MaintenanceStepCustomer,
    MaintenanceStepInfo,
    MaintenanceStepTasks,
    MaintenanceStepVehicle,
  },

  props : {
    value: {
      type: Object,
      required: true,
    },
    errors:{
      type: Object,
    },
  },

  setup( props, ctx ){

    const { vModel : maintenance } = useVModel( props.value, ctx )
    const { info } = useNotify()

    const steps = 4
    const step = ref(1)
    const isFirstStep = computed( () => step.value === 1 )
    const isLastStep = computed( () => steps === step.value )

    const hasCustomer = computed( () => {
      return maintenance.value.customer !== undefined && maintenance.value.customer.id > 0
    })

    const customerCaption = computed( () => {
      if( hasStepError( 1 ) ) return 'non hai selezionato il cliente'
      if( step.value < 2 ) return 'seleziona o registra il cliente'
      return maintenance.value.customer.fullname
    })

    const hasVehicle = computed( () => {
      return maintenance.value.vehicle !== undefined && maintenance.value.vehicle.id > 0
    })

    const vehicleCaption = computed( () => {
      if( hasStepError( 2 ) ) return 'non hai selezionato il veicolo'
      if( step.value < 3 ) return 'aggiorna o registra i dati del veicolo'
      return `${maintenance.value.vehicle.model.brand.name} ${maintenance.value.vehicle.model.name} ${maintenance.value.vehicle.model.year}`
    })

    //set customer and mileage by vehicle
    watch( () => maintenance.value.vehicle, value => {
      if( hasVehicle.value ) {
        //owner
        if( !hasCustomer.value && value.owner.id ){
          maintenance.value.customer = cloneDeep( value.owner )
          info( `${maintenance.value.customer.fullname} impostato come cliente` )
        }
      }
    })

    //clear vehicle if clear customer
    watch( () => maintenance.value.customer, value => {
      if( !value.id && hasVehicle.value && maintenance.value.vehicle.owner.id ){
        maintenance.value.vehicle = {}
      }
    })

    function hasStepError( stepToCheck )
    {
      let error = false

      switch( stepToCheck ){
        case 1:
          error = step.value > 1 && !hasCustomer.value
          break;
        case 2:
          error = step.value > 2 && !hasVehicle.value
          break;
      }

      return error
    }

    return {
      customerCaption,
      vehicleCaption,
      hasCustomer,
      hasVehicle,
      hasStepError,
      isFirstStep,
      isLastStep,
      maintenance,
      step,
      steps
    }
  }

}
</script>

