<template>
  <div>
    <MaintenanceCard
      class="q-mt-lg"
      subtitle="informazioni scheda manutenzione"
      v-if="!!maintenance.vehicle"
      :maintenance="maintenance"
    />

    <template v-if="state.detailEdit">
      <BaseCard title="Dettagli operazione" subtitle="compila i campi">
        <OperationForm
          :errors="errors"
          :tasks="maintenance.tasks"
          v-model="state.editable"
        />
      </BaseCard>

      <BaseCard
        title="Ricambi utilizzati"
        subtitle="pezzi di ricambio utilizzati"
      >
        <OperationSpareParts
          v-model="state.editable.spare_parts"
          v-if="!!maintenance.vehicle"
          :vehicle="maintenance.vehicle.id"
          :operation="state.detail.id"
        />
      </BaseCard>

      <q-card-section align="center">
        <BaseBtn
          class="q-pr-sm q-mr-md"
          label="Salva"
          color="pink"
          icon="mdi-content-save-outline"
          @click="save"
          :disabled="!state.detailDirty"
        />

        <BaseBtn
          class="q-px-sm"
          label="Esci dalla modifica"
          color="black"
          @click="show"
        />
      </q-card-section>
    </template>

    <OperationCard v-else-if="state.detail.id" :operation="state.detail">
      <BaseBtn
        label="Modifica"
        class="q-pr-sm q-mr-md"
        icon="mdi-pencil"
        @click="edit"
      />

      <BaseBtn
        label="Elimina"
        class="q-pr-sm"
        color="negative"
        icon="mdi-delete"
        @click="destroy"
      />
    </OperationCard>

    <!-- <q-page-sticky -->
    <!-- draggable -->
    <!-- v-if="!state.detailEdit" -->
    <!-- :offset="[18, 18]"> -->
    <!-- <q-fab -->
    <!-- vertical-actions-align="right" -->
    <!-- direction="up" -->
    <!-- color="pink" -->
    <!-- icon="mdi-star-outline" -->
    <!-- label="Azioni" -->
    <!-- > -->
    <!-- <q-fab-action -->
    <!-- color="secondary" -->
    <!-- icon="mdi-pencil" -->
    <!-- label="Modifica" -->
    <!-- @click="edit" /> -->

    <!-- <q-fab-action -->
    <!-- color="negative" -->
    <!-- icon="mdi-delete" -->
    <!-- label="Eliminia" -->
    <!-- @click="destroy" /> -->

    <!-- <q-fab-action -->
    <!-- color="positive" -->
    <!-- icon="mdi-plus" -->
    <!-- label="Crea nuova" -->
    <!-- @click="create" /> -->

    <!-- </q-fab> -->
    <!-- </q-page-sticky> -->

    <BaseModal
      confirm
      v-model="state.detailDestroy"
      @close="show"
      @confirm="apiDestroy(state.detail.id)"
      title="Conferma richiesta"
      label="Elimina"
      ko-label="Annulla"
    >
      Rimuovere l'operazione selezionata?
    </BaseModal>
  </div>
</template>

<script>
import Attachs from '../../apis/attachs.js'
import MaintenanceCard from '../maintenances/MaintenanceCard.vue'
import Operations from '../../apis/operations.js'
import OperationCard from './OperationCard.vue'
import OperationForm from './OperationForm.vue'
import OperationSpareParts from './OperationSpareParts.vue'
import useApiCrud from '../../hooks/apiCrud.js'
import useNotify from '../../hooks/notify.js'
import { onMounted, watch } from '@vue/composition-api'

export default {
  components: {
    MaintenanceCard,
    OperationCard,
    OperationForm,
    OperationSpareParts,
  },

  props: {
    operation: {
      type: [String, Number],
    },

    action: {
      type: String,
    },

    maintenance: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const {
      apiDestroy,
      apiShow,
      errors,
      state,
      save: apiSave,
      setEditable,
    } = useApiCrud({
      api: Operations,
      ctx,
      routed: false,
      events: {
        //add maintenance id
        beforeStore(data) {
          return { ...data, maintenance_id: props.maintenance.id }
        },
        store(response) {
          //route to list
          ctx.emit('store', response.data)
          ctx.root.$router.push({ name: 'maintenanceShow' })
          return false
        },
        update(response) {
          ctx.emit('update', response.data)
          return response
        },
        destroy() {
          //route to list
          ctx.emit('destroy', state.detail.id)
          ctx.root.$router.push({ name: 'maintenanceShow' })
          success('Eliminazione completata')
          return false
        },
      },
    })

    const { success, ongoing, update: notifyUpdate } = useNotify()

    //set data
    onMounted(() => apiShow(props.operation))

    //watch actions
    watch(
      () => props.action,
      (value) => (state.action = value || ''),
      { immediate: true }
    )

    async function save() {
      //prepare spare parts to submit
      state.editable.spare_parts.map((sparePart) => {
        //id new stripped
        if (/^new_/.test(sparePart.id)) {
          delete sparePart.id
        }
        //remove not true
        if (sparePart.remove !== undefined && !sparePart.remove) {
          delete sparePart.remove
        }
      })

      //attachs
      let notifyLoading = ''
      for (let attachIndex in state.editable.attachs) {
        let attach = state.editable.attachs[attachIndex]

        //to upload need
        if (!attach.id && !attach.remove) {
          //start upload
          notifyLoading = ongoing(`Caricamento del file "${attach.name}"`)

          await Attachs.upload(attach)
            .then((response) => {
              notifyUpdate(notifyLoading, `Caricamento completato`)

              //set attach
              state.editable.attachs[attachIndex] = response.data
            })
            .catch(() => {
              notifyUpdate(
                notifyLoading,
                `Caricamento del file "${attach.name} non riuscito"`,
                { type: 'negative', icon: 'mdi-alert-outline' }
              )
            })
        }
      }

      apiSave()
    }

    function edit() {
      ctx.root.$router
        .push({ params: { operation_action: 'edit' } })
        .then(() => setEditable(state.detail))
    }

    function create() {
      ctx.root.$router
        .push({ params: { operation: 'create', operation_action: null } })
        .then(() => apiShow(props.operation))
    }

    function destroy() {
      ctx.root.$router.push({ params: { operation_action: 'destroy' } })
    }

    function show() {
      ctx.root.$router.push({ params: { operation_action: null } })
    }

    return {
      apiDestroy,
      create,
      destroy,
      edit,
      errors,
      state,
      save,
      show,
    }
  },
}
</script>
