<template>
  <tr>
    <td class="text-left bg-grey-2">{{ data.code }}</td>
     <td class="text-left">
       <q-item-label overline>
         {{ data.brand.name }}
       </q-item-label>
       <q-item-label>
         {{ data.name }}
       </q-item-label>
       <q-item-label caption>
         {{ abstract }}
       </q-item-label>
    </td>
    <td class="text-right bg-primary text-white">{{ data.quantity }}</td>
    <td class="text-right bg-secondary text-white">{{ formatMoney( data.cost ) }}</td>
    <td class="text-right bg-dark text-white">{{ totalCost }}</td>
    <td class="text-right bg-secondary text-white">{{ formatMoney( data.price ) }}</td>
    <td class="text-right bg-dark text-white">{{ totalPrice }}</td>
  </tr>
</template>

<script>
import { formatMoney } from '../../hooks/helpers.js'
import { computed } from '@vue/composition-api'

export default {
  name: 'SparePartsTableRow',

  props: {
    data: {
      type: Object,
    },
  },

  setup({ data }){
    const totalCost = computed( () => formatMoney( data.quantity * data.cost ) )
    const totalPrice = computed( () => formatMoney( data.quantity * data.price ) )

    const abstract = computed( () => data.description ? ( data.description.length > 50 ? `${data.description.substr(0,50)} [...]` : data.description ) : '' )

    return {
      abstract,
      formatMoney,
      totalCost,
      totalPrice,
    }
  },
}
</script>

