<template>
  <q-item-section :side="!inline" :top="!inline">
    <div class="flex" :class="{ column: !inline }">
      <q-item-label
        caption
        class="text-overline text-bold text-secondary"
        :class="{ 'q-mb-sm': !inline }"
      >
        <q-icon name="mdi-wrench" style="margin-top: -2px"></q-icon>
        <template v-if="!inline"> Pianificazione </template>
        <q-tooltip v-else>Pianificazione</q-tooltip>
      </q-item-label>

      <template v-for="(section, index) in planningSection">
        <q-badge
          :class="{ 'q-mt-sm': !inline, 'q-ml-sm': inline }"
          :key="`section_${index}`"
          :color="!!section.value ? 'primary' : 'warning'"
        >
          <q-tooltip>{{ section.label }}</q-tooltip>

          <q-icon :name="`mdi-${section.icon}`" class="q-mr-xs" />
          |
          {{ section.value || '-' }}
        </q-badge>
      </template>
    </div>
  </q-item-section>
</template>

<script>
import { date } from 'quasar'
import { ref } from '@vue/composition-api'

export default {
  name: 'TaskPlanning',

  props: {
    task: {
      type: Object,
    },
    inline: {
      type: Boolean,
    },
  },

  setup(props) {
    const { task } = props

    const planningSection = ref([
      {
        label: 'Operatore',
        icon: 'account-outline',
        value: task.user.id ? task.user.name : null,
      },
      {
        label: 'Data',
        icon: 'calendar-outline',
        value: task.executed_at
          ? date.formatDate(task.executed_at, 'DD/MM/YYYY')
          : null,
      },
      {
        label: 'Ore Previste',
        icon: 'timer-outline',
        value: task.total_time > 0 ? task.total_time : null,
      },
    ])

    return {
      planningSection,
    }
  },
}
</script>
