<template>

  <BaseModal
    v-bind="{ persistent: false, closeBtn:true, ...$attrs }"
    v-on="$listeners"
    title="Storico stati"
    label="Chiudi"
    color="secondary">

    <q-list>
      <template v-for="(hItem,index) in history">

        <q-item
          class="rounded-borders q-mt-xs q-mb-xs"
          style="position:relative;min-height:12px"
          v-if="index>0"
          :key="`sh_sep_${index}`">
          <div
            class="rounded-borders bg-secondary"
            style="width:8px;height:8px;position:absolute;top:4px;left:0;"
          />
          <q-separator />
        </q-item>

        <q-item
          class="rounded-borders"
          style="position:relative"

          :key="`sh_${index}`">

          <div
            class="rounded-borders"
            style="width:8px;height:100%;position:absolute;top:0;left:0;"
            :class="`bg-${getStatusById(hItem.id).value.color}`"
          />

          <q-item-section class="q-ml-xs">
            <q-item-label>{{ hItem.name  }}</q-item-label>
            <q-item-label caption lines="2">
              <q-icon name="mdi-account"/> {{ hItem.created_from }}
            </q-item-label>
          </q-item-section>

          <q-item-section side top>
            <q-item-label caption>{{ formatDate( hItem.created_at, 'DD/MM/YYYY HH:mm' )  }}</q-item-label>
          </q-item-section>

        </q-item>

      </template>
    </q-list>

  </BaseModal>

</template>

<script>
import { useGetters } from '../../hooks/store.js'
import { date } from 'quasar'

export default{

  props: {
   history: {
      type: Array,
    },
  },

  setup(){
    const { formatDate } = date
    const { getStatusById } = useGetters( 'maintenance', [ 'getStatusById' ] )

    return {
      formatDate,
      getStatusById,
    }
  },

}
</script>

